import "./public-transport-preference-subdomain.scss";
import * as template from "./public-transport-preference-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { PublicTransportPreferenceSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { PublicTransportPreferencePointFillLayer } from "../../../layers/transit/public-transport-preference-point-fill-layer";
import { PublicTransportPreferencePointSymbolLayer } from "../../../layers/transit/public-transport-preference-point-symbol-layer";
import { PublicTransportVehicleDelayCircleLayer } from "../../../layers/transit/public-transport-vehicle-delay-circle-layer";
import { PublicTransportVehicleLabelLayer } from "../../../layers/transit/public-transport-vehicle-label-layer";
import { PublicTransportVehicleHeadingLayer } from "../../../layers/transit/public-transport-vehicle-heading-layer";
import { PublicTransportPreferenceAreaBoundaryLayer } from "../../../layers/transit/public-transport-preference-area-boundary-layer";
import { PublicTransportPreferenceAreaLabelLayer } from "../../../layers/transit/public-transport-preference-area-label-layer";
import { PublicTransportPreferenceAreaSymbolLayer } from "../../../layers/transit/public-transport-preference-area-symbol-layer";
import { AreaList } from "../../city/area-list/area-list";
import { PublicTransportPreferenceVehicleCircleLayer } from "../../../layers/transit/public-transport-preference-vehicle-circle-layer";
import { PublicTransportVehicleHistoryCircleLayer } from "../../../layers/transit/public-transport-vehicle-history-circle-layer";

export class PublicTransportPreferenceSubdomain extends CitySubdomain<PublicTransportPreferenceSubdomainOptions> {

    constructor(context: InvipoContext, options?: PublicTransportPreferenceSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Points",
                    areaType: "PreferenceDetectionArea,PreferenceDetectionPoint",
                    layers: [
                        new PublicTransportPreferencePointFillLayer(context),
                        new PublicTransportPreferencePointSymbolLayer(context),
                        new PublicTransportPreferenceVehicleCircleLayer(context),
                        new PublicTransportVehicleHistoryCircleLayer(context),
                        new PublicTransportVehicleDelayCircleLayer(context),
                        new PublicTransportVehicleLabelLayer(context),
                        new PublicTransportVehicleHeadingLayer(context),
                        new PublicTransportPreferenceAreaBoundaryLayer(context),
                        new PublicTransportPreferenceAreaLabelLayer(context),
                        new PublicTransportPreferenceAreaSymbolLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.AreaList.subtitle",
            layers: []
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public async extraLoad(): Promise<void> {
        // Create legend
        this.legend = {
            range: null,
            symbols: [
                {
                    type: "Circle",
                    symbol: METRICS.traffic.preference.points.range[0],
                    count: this.areas.filter(x => x.type == "PreferenceDetectionArea").length,
                    label: `components.PublicTransportPreferenceSubdomain.legend.${METRICS.traffic.preference.points.range[0]}`,
                    color: METRICS.traffic.preference.points.colors[0]
                },
                {
                    type: "Circle",
                    symbol: METRICS.traffic.preference.points.range[1],
                    count: this.areas.filter(x => x.type == "PreferenceDetectionPoint").length,
                    label: `components.PublicTransportPreferenceSubdomain.legend.${METRICS.traffic.preference.points.range[1]}`,
                    color: METRICS.traffic.preference.points.colors[1]
                }
            ]
        };
    }

}
