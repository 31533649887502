import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterAction, FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { Templates } from "../../../../hiyo/templates";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { COLUMN_RESOLVED_VIOLATION, COLUMN_VIOLATION_IN_PROCESS, COLUMN_VIOLATION_NOTICE, HanoiViolationBrowser } from "../hanoi-violation-browser/hanoi-violation-browser";
import { HanoiViolationReportBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { Dialog } from "../../../../muklit/components/dialog/dialog";
import { Autocomplete } from "muklit/components/autocomplete/autocomplete";

export class HanoiViolationDecisionBrowser extends HanoiViolationBrowser<HanoiViolationReportBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data?workflow.column=${COLUMN_VIOLATION_IN_PROCESS},${COLUMN_VIOLATION_NOTICE}&extras.report=true&extras.decision=true`,
            http: this.context.invipo.http,
            filter: {
                title: `components.${this.name}.title`,
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                actions: [
                    {
                        name: "ComplyDecision",
                        label: "components.HanoiViolationDecisionBrowser.actions.complyDecision",
                        multiselect: true,
                        disabled: true
                    },
                    {
                        name: "DeleteDecision",
                        label: "components.HanoiViolationDecisionBrowser.actions.deleteDecision",
                        escalated: true,
                        multiselect: true,
                        disabled: true
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("ViolationsData", "type"), "enums.ViolationType."),
                                multiselect: true
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "ViolationsData",
                                items: [],
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.plate.number",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "data.incident.extras.class",
                                label: "forms.fields.category",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toCategoryItems(this.context.config.categories)
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "data.incident.extras.color",
                                label: "forms.fields.color",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.VehicleColor"))
                            }),
                        ]
                    },
                    {
                        name: "speed",
                        type: "Inline",
                        fields: [
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.min",
                                label: "forms.fields.speedMin",
                                placeholderText: "forms.placeholders.speedMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.max",
                                label: "forms.fields.speedMax",
                                placeholderText: "forms.placeholders.speedMax"
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        let result = null;

                        // Archived?
                        if (data.workflow?.archived) {
                            result = "disabled"
                        }

                        // Completed?
                        if (data.workflow?.completed) {
                            result = "disabled"
                        }

                        return result;
                    }
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.ViolationType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        width: 160,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "data.incident.extras.plate.number",
                        type: "String",
                        property: "data.incident.extras.plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.data.incident.extras.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "extras.decision.timestamp",
                        type: "Date",
                        property: (data: any) => {
                            return new Date( new Date(data.extras.decision.timestamp).getTime() + (86400000 * data.extras.decision.finePeriod));
                        },
                        label: "tables.columns.deadline",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "extras.decision.status",
                        type: "String",
                        property: "extras.decision.status",
                        label: "tables.columns.status",
                        formatter: (value: any, data: any): string => {
                            // Set status and color
                            let status = "";
                            let color = null;

                            // Calculate parameter for status conditions
                            let days = Math.round((Date.now() - new Date(data.extras.decision.timestamp).getTime()) / (1000 * 60 * 60 * 24));

                            if (days < data.extras.decision.finePeriod) {
                                status = "Within the decision period";
                            }
                            else {
                                status = "Decision period expired";
                                color = "#ff0038";
                            }

                            return `<div class="cell cell-ellipsis" ${color ? `style="color: ${color}"` : ""}>${status}</div>`;
                        },
                        minWidth: 260,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Handle action  selection
        this.table.onActionSelect = async (item: FilterAction) => {
            // Confirm violation?
            if (item.name == "ComplyDecision") {
                // Open violation dialog
                await this.complyDecision();
            }
            // Delete?
            if (item.name == "DeleteDecision") {
                // Open violation dialog
                this.deleteDecision();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public async complyDecision(): Promise<any> {
        // Show loader
        this.showLoader();

        // Itterate all selected rows
        for (let id in this.table.table.selectedRows) {
            // Update column
            await this.context.invipo.putManagement(`violations/${id}/workflow/column`, {
                column: COLUMN_RESOLVED_VIOLATION
            });
        }

        // Hide loader and redraw
        this.hideLoader();

        // Close detal
        this.detail?.close();

        // Unselect all checkboxes
        this.table.unselectCheckboxes();

        // Relaod
        await this.table.load();
    }

    public deleteDecision(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.HanoiViolationDecisionBrowser.deleteDecision",
            text: "components.HanoiViolationDecisionBrowser.reallyDeleteDecision",
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Close dialog
            dialog.close();

            // Show loader
            this.showLoader();

            // Itterate all selected rows
            for (let id in this.table.table.selectedRows) {
                // Get violation data
                let violation = this.table.table.selectedRows[id].data;

                // Delete decision
                delete violation.extras?.decision;

                // Remove report
                await this.context.invipo.putManagement(`violations/${id}/extras`, {
                    extras: violation.extras
                });
            }

            // Hide loader and redraw
            this.hideLoader();

            // Close detail
            this.detail?.close();

            // Unselect all checkboxes
            this.table.unselectCheckboxes();

            // Relaod
            await this.table.load();
        }

        // Show dialog
        dialog.attach();
    }
}
