import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { InvipoItem } from "../../clients/invipo-client/types";
import { Helpers } from "../../../hiyo/helpers";

export class DigitalKioskSessionsLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "DigitalKioskCard",
            layer: {
                id: "digital-kiosk-sessions-label",
                type: "symbol",
                minzoom: ZOOM_LABEL - 3,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": ["get", "color"],
                    "text-opacity": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "DigitalKiosk"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Sort by level
        items.sort((a: InvipoItem, b: InvipoItem) => {
            return (a.data?.usage?.sessions ?? 0) - (b.data?.usage?.sessions ?? 0);
        });

        // Push features from items
        for (let item of items) {
            json.features.push({
                type: "Feature",
                properties: {
                    data: "sessions",
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: "#161616",
                    label: (item.data?.usage?.sessions != null) ? Helpers.toNumber(item.data.usage.sessions) : "N/A"
                },
                geometry: item.geometry["location"]
            });
        }

        return json;
    }
}
