import "./luna-human-image-input.scss";
import * as template from "./luna-human-image-input.hbs";
import { Context } from "hiyo/context";
import { Input } from "muklit/components/input/input";
import { LunaHumanImageInputOptions } from "./types";
import { Log } from "hiyo/log";
import { InvipoContext } from "invipo/context/invipo-context";
import { cli } from "webpack";

export const IMAGE_TYPES = ["image/jpeg", "image/png"];

export class LunaHumanImageInput extends Input<InvipoContext, LunaHumanImageInputOptions> {

    // Properties
    public name: string;
    public image: string
    public faceDescriptors: any[];
    public bodyDescriptors: any[];

    constructor(context: InvipoContext, options: LunaHumanImageInputOptions) {
        super(context, template, options);
    }

    public dragOver(e: DragEvent): void {
        // Stop propagation
        e.stopPropagation();
        e.preventDefault();
    }

    public dragEnter(e: DragEvent): void {
        // Add active class
        this.query("div.upload").classList.add("upload-active");
    }

    public dragLeave(e: DragEvent): void {
        // Remove active class
        this.query("div.upload").classList.remove("upload-active");
    }

    public drop(e: DragEvent): void {

        e.stopPropagation();
        e.preventDefault();

        // First file
        let file = e.dataTransfer.files[0];

        // Upload image
        this.upload(file);
    }

    public upload(file: File): void {
        // Reset the previous values
        this.image = null;
        this.name = null;
        this.faceDescriptors = [];
        this.bodyDescriptors = [];
        this.setInvalid(false);

        // No file?
        if (!file) {
            Log.w(`LunaHumanImageInput: No file dropped (empty data)`);
            return;
        }

        // Unsupported type?
        if (!IMAGE_TYPES.includes(file.type)) {
            Log.w(`LunaHumanImageInput: Unsupported image type (${file.type})`);

            this.setInvalid(true, "forms.validation.invalidFileFormat")
            return;
        }

        if (this.options.limit && file.size > this.options.limit * 1024) {
            Log.w(`LunaHumanImageInput: File size exceeds limit (${file.size} > ${this.options.limit})`);

            this.setInvalid(true, "forms.validation.fileExceedsLimit");
            return;
        }

        // Assign file options
        this.name = file.name;

        // We nead reader to read a file
        let  reader = new FileReader();
        reader.addEventListener("load", (e: ProgressEvent<FileReader>): void => {
            this.setImage(e.target.result.toString());
        });

        // Read file
        reader.readAsDataURL(file);
    }

    public async setImage(base64: string): Promise<void> {
        // Add Base64 image data as input value
        this.image = base64;
        this.faceDescriptors = [];
        this.bodyDescriptors = [];

        // Redraw
        this.update();
        this.element.classList.add("loading");

        if (!this.options.type || this.options.type === "Face") {
            await this.loadFaceDescriptors();
        }

        if (!this.options.type || this.options.type === "Body") {
            await this.loadBodyDescriptors();

            if (!this.bodyDescriptors?.length) {
                Log.w(`LunaHumanImageInput: No body descriptors found`);

                this.setInvalid(true, "forms.validation.noBodyDescriptors");
                return;
            }
        }

        this.element.classList.remove("loading");
    }

    public async loadFaceDescriptors(): Promise<void> {
        const data = await this.context.invipo.postExternal("/luna/descriptor", {
            type: "face",
            image: this.image
        });

        const uploadElem = this.query("div.upload");
        const img = this.query("div.upload img") as HTMLImageElement;

        const elemAspect = img.clientWidth / img.clientHeight;
        const imgAspect = img.naturalWidth / img.naturalHeight;

        let scale = elemAspect < imgAspect ? img.clientWidth / img.naturalWidth : img.clientHeight / img.naturalHeight;
        const size = { width: img.naturalWidth * scale, height: img.naturalHeight * scale }

        const offset = {
            x: (uploadElem.clientWidth - size.width) / 2 - 2,
            y: (uploadElem.clientHeight - size.height) / 2 - 2
        };

        this.faceDescriptors = []
        for (const estimation of data[0].estimations) {
            this.faceDescriptors.push({
                descriptor: estimation.face?.detection?.attributes?.descriptor?.sdk_descriptor,
                score: estimation.face?.detection?.attributes?.descriptor?.score,
                x: estimation.face?.detection?.rect?.x * scale + offset.x,
                y: estimation.face?.detection?.rect?.y * scale + offset.y,
                width: estimation.face?.detection?.rect?.width * scale,
                height: estimation.face?.detection?.rect?.height * scale
            })
        }

        // Validate the result
        if (!this.faceDescriptors?.length) {
            Log.w(`LunaHumanImageInput: No face descriptors found`);

            this.setInvalid(true, "forms.validation.noFaceDescriptors");
            return;
        }

        this.update();

        if (this.faceDescriptors.length === 1) {
            this.selectFace(this.faceDescriptors[0].descriptor);
        }
    }

    public async loadBodyDescriptors(): Promise<void> {
        const data = await this.context.invipo.postExternal("/luna/descriptor", {
            type: "body",
            image: this.image
        });

        const uploadElem = this.query("div.upload");
        const img = this.query("div.upload img") as HTMLImageElement;

        const elemAspect = img.clientWidth / img.clientHeight;
        const imgAspect = img.naturalWidth / img.naturalHeight;

        let scale = elemAspect < imgAspect ? img.clientWidth / img.naturalWidth : img.clientHeight / img.naturalHeight;

        const size = { width: img.naturalWidth * scale, height: img.naturalHeight * scale }

        const offset = {
            x: (uploadElem.clientWidth - size.width) / 2 - 2,
            y: (uploadElem.clientHeight - size.height) / 2 - 2
        };

        this.bodyDescriptors = []
        for (const estimation of data[0].estimations) {
            this.bodyDescriptors.push({
                descriptor: estimation.body?.detection?.attributes?.descriptor?.sdk_descriptor,
                score: estimation.body?.detection?.attributes?.descriptor?.score,
                x: estimation.body?.detection?.rect?.x * scale + offset.x,
                y: estimation.body?.detection?.rect?.y * scale + offset.y,
                width: estimation.body?.detection?.rect?.width * scale,
                height: estimation.body?.detection?.rect?.height * scale
            })
        }

        // Validate the result
        if (!this.bodyDescriptors?.length) {
            Log.w(`LunaHumanImageInput: No body descriptors found`);

            this.setInvalid(true, "forms.validation.noBodyDescriptors");
            return;
        }

        this.update();

        if (this.bodyDescriptors.length === 1) {
            this.selectBody(this.bodyDescriptors[0].descriptor);
        }
    }

    public selectFace(descriptor: any, e?: MouseEvent): void {
        e?.stopPropagation();
        e?.preventDefault();

        this.options.value = descriptor;
        this.update();
    }

    public selectBody(descriptor: any, e?: MouseEvent): void {
        e?.stopPropagation();
        e?.preventDefault();

        this.options.value = descriptor;
        this.update();
    }

    public select(): void {
        // Get input
        let input = this.query("input");

        // Force dialog to open
        input.click();
    }

    public clear(e: MouseEvent): void {
        // Stop propagation
        e.stopPropagation();
        e.preventDefault();

        // Reset options
        this.options.value = null;

        // Reset properties
        this.name = null;
        this.image = null;
        this.faceDescriptors = [];
        this.bodyDescriptors = [];

        // Redraw
        this.update();
    }


    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }
    }

}
