import "./hanoi-violation-detail.scss";
import * as template from "./hanoi-violation-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { TabsItem } from "muklit/components/tabs/types";
import { HanoiViolationDetailOptions } from "./types";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { ViolationCommentForm } from "../violation-comment-form/violation-comment-form";
import { OffensePreview } from "../../traffic/offense-preview/offense-preview";
import { VehicleRegistryDetail } from "../../traffic/vehicle-registry-detail/vehicle-registry-detail";
import { VideoDetail } from "../../common/video-detail/video-detail";
import { Point } from "geojson";
import { Dialog } from "../../../../muklit/components/dialog/dialog";
import { HanoiViolationDataForm } from "../hanoi-violation-data-form/hanoi-violation-data-form";
import { HanoiViolationReportForm } from "../hanoi-violation-report-form/hanoi-violation-report-form";
import { HanoiViolationDecisionForm } from "../hanoi-violation-decision-form/hanoi-violation-decision-form";

export class HanoiViolationDetail extends Detail<InvipoContext, HanoiViolationDetailOptions> {

    // Properties
    public violation: any;
    public documentImages: string[] ;

    // Components
    public tabs: Tabs;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: HanoiViolationDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Tabs
        let tabs: TabsItem[] = [
            {
                name: "Violation",
                label: "components.HanoiViolationDetail.violation",
                content: "div.content-violation",
                selected: true
            },
            {
                name: "Notice",
                label: "components.HanoiViolationDetail.notice",
                content: "div.content-notice",
                disabled: true
            },
            {
                name: "Report",
                label: "components.HanoiViolationDetail.report",
                content: "div.content-report",
                disabled: true
            },
            {
                name: "Decision",
                label: "components.HanoiViolationDetail.decision",
                content: "div.content-decision",
                disabled: true
            },
            {
                name: "Stream",
                label: "components.HanoiViolationDetail.stream",
                content: "div.content-stream"
            }
        ];

        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: tabs
        });
    }

    public openVideo(): void {
        // New image detail
        let detail = new VideoDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            timestamp: new Date(new Date(this.violation.data.incident.timestamp).getTime() - 1000).toISOString(),
            position: (<Point>this.violation.data.incident.item.geometry.location).coordinates,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public openIncidentOffense(): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: "incident.offense",
            data: this.violation.data.incident,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openReportOffense(): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: "violation.report",
            data: this.violation,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openDecisionOffense(): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: "violation.decision",
            data: this.violation,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openNoticeOffense(key: string): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: key,
            data: this.violation,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openRegistry(): void {
        // Detail form
        let detail = new VehicleRegistryDetail(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true,
            lpn: this.violation.data.incident.extras.plate?.number
        });

        // Show form
        detail.attach();
    }

    public openData(): void {
        // Dialog to confirm
        let form = new HanoiViolationDataForm(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            violation: this.violation
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public async selectAssignee(e: MouseEvent): Promise<void> {
        // Create component
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "TopRight",
            anchor: "TopRight",
            items: []
        });

        // Get users
        let users = await this.context.invipo.getDataset("users");

        // Push languages as items
        for (let user of users.data) {
            menu.options.items.push(
                {
                    name: user.id,
                    label: user.name,
                    disabled: this.violation.workflow.assignee?.id == user.id
                }
            )
        }

        // Change language
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Show loader
            this.showLoader();

            // Update column
            await this.context.invipo.putManagement(`violations/${this.violation.id}/workflow/assignee`, {
                assignee: item.name
            });

            // Hide loader and redraw
            this.hideLoader();
            await this.load();

            // OnUpdate handler
            this.onDetailUpdate();
        }

        // Show menu
        menu.show(<HTMLElement>e.target);
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public showCommentMenu(i: number, event: MouseEvent): void {
        // Create overlfow menu
        let menu = new OverflowMenu(this.context, {
            style: this.options.style,
            anchor: "TopLeft",
            start: "TopLeft",
            offset: [0, 8],
            items: [
                {
                    name: "Edit",
                    label: "labels.edit"
                },
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        });

        // OnMenuSelect handler
        menu.onSelect = (item: MenuItem) => {
            // Edit comment?
            if (item.name == "Edit") {
                this.openComment(i);
            }
            // Delete comment?
            if (item.name == "Delete") {
                this.deleteComment(i);
            }
        }

        // Show over menu button
        menu.show(null, event.pageX, event.pageY);
    }

    public openComment(i: number): void {
        // Dialog to confirm
        let form = new ViolationCommentForm(this.context, {
            style: "Light",
            title: (i >= 0) ? "components.ViolationCommentForm.titleEdit" : "components.ViolationCommentForm.titleAdd",
            violationId: this.violation.id,
            commentText: (i >= 0) ? this.violation.stream[i].text : null,
            commentIndex: i,
            overlay: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public deleteComment(i: number): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.HanoiViolationDetail.deleteComment",
            text: "components.HanoiViolationDetail.reallyDeleteComment",
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteManagement(`/violations/${this.violation.id}/comments/${i}`);

            // Close dialog
            dialog.close();

            // Reload
            await this.load();
        }

        // Show dialog
        dialog.attach();
    }

    public openReport(): void {
        // Dialog to confirm
        let form = new HanoiViolationReportForm(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            violation: this.violation
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public openDecision(): void {
        // Dialog to confirm
        let form = new HanoiViolationDecisionForm(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            violation: this.violation
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.violation = await this.context.invipo.getManagement(`violations/${this.options.violationId}`);

        // Add full item to context (we need meta data)
        this.violation.data.incident.item = this.context.data.getItem(this.violation.item.id);

        // Enable report and decision tabs
        this.tabs.options.tabs.find(x => x.name == "Notice").disabled = !this.violation.extras?.notice;
        this.tabs.options.tabs.find(x => x.name == "Report").disabled = !this.violation.extras?.report;
        this.tabs.options.tabs.find(x => x.name == "Decision").disabled = !this.violation.extras?.decision;

        this.documentImages = [];
        if (this.violation.extras?.report?.documentIdFront) this.documentImages.push(this.violation.extras.report.documentIdFront);
        if (this.violation.extras?.report?.documentIdBack) this.documentImages.push(this.violation.extras.report.documentIdBack);
        if (this.violation.extras?.report?.documentLicenseFront) this.documentImages.push(this.violation.extras.report.documentLicenseFront);
        if (this.violation.extras?.report?.documentLicenseBack) this.documentImages.push(this.violation.extras.report.documentLicenseBack);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
