import "./human-browser.scss";
import * as template from "./human-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { HumanBrowserOptions } from "./types";
import { HumanDetailOptions } from "../human-detail/types";
import { HumanDetail } from "../human-detail/human-detail";
import { BasicMap } from "muklit/components/basic-map/basic-map";

export abstract class HumanBrowser<T extends HumanBrowserOptions> extends MuklitComponent<InvipoContext, T> {

    // Components
    public table: FilterTable;
    public map: BasicMap;
    public mapToggled: boolean;
    public detail: HumanDetail;

    constructor(context: InvipoContext, options?: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
        this.createMap();
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public abstract createTable(): void;

    public createMap(): void {};

    public toggleMap(open?: boolean): void {
        let map = this.query("div.map");
        if (!map) return;

        let hidden = map.classList.contains("map-hidden");

        if (open == true) {
            // Force open
            map.classList.remove("map-hidden");
            this.mapToggled = true;
        }
        else if (open == false) {
            // Force close
            map.classList.add("map-hidden");
            this.mapToggled = false;
        }
        else {
            // Toggle map visibility
            map.classList.toggle("map-hidden", !hidden);
            this.mapToggled = hidden;
        }

        // Resize map to fit the borders
        this.map.resize();
    }

    protected openDetail(data: any, title?: string): void {
        // Detail options
        let options: HumanDetailOptions = {
            style: "Light",
            human: data,
            title: title || "components.HumanDetail.title",
            subtitle: data.item?.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new HumanDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.human.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }
}
