import "./watchlist-import-form.scss";
import * as template from "./watchlist-import-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { WatchlistImportFormOptions } from "./types";
import { FileInput } from "muklit/components/file-input/file-input";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "invipo/invipo-helpers";
import { Http, HttpMethod } from "hiyo/http";

export class WatchlistImportForm extends Detail<InvipoContext, WatchlistImportFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: WatchlistImportFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // CSV upload form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Row",
                    fields: [
                        new Select(this.context, {
                            style: "Light",
                            name: "watchlist",
                            label: "forms.fields.watchlist",
                            width: 284,
                            items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "watchlist")),
                            multiselect: true,
                            bright: true,
                            required: true,
                        }),
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new FileInput(this.context, {
                            style: "Light",
                            name: "file",
                            label: "forms.fields.file",
                            width: 284,
                            height: 150,
                            required: true,
                            type: "text/csv",
                            limit: 2024
                        })
                    ]
                }
            ]
        });
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // Create form data for file upload
        const formData = new FormData();
        formData.append("watchlist", data.watchlist);

        // Convert base64 to file
        const byteString = atob(data.file.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: 'text/csv' });
        const file = new File([blob], "watchlist.csv", { type: 'text/csv' });
        formData.append("file", file);

        // Upload CSV file
        try {
            const url = `${this.context.options.host}/api/data/imports/watchlists-vehicles-csv`;
            await this.context.invipo.http.request(HttpMethod.POST, url, {
                form: formData
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
