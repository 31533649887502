import "./overflow-menu.scss";
import * as template from "./overflow-menu.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { MenuItem, OverflowMenuOptions } from "./types";

export const TIMEOUT_DOCUMENT_LISTENER = 150; // ms

export class OverflowMenu extends MuklitComponent<Context, OverflowMenuOptions> {

    // Properties
    private hideListener = () => this.hide();
    private keyListener = (e: KeyboardEvent) => this.key(e);

    // Event handling methods
    public onSelect(item: MenuItem): void {};
    public onUnselect(item: MenuItem): void {};

    constructor(context: Context, options: OverflowMenuOptions) {
        super(context, template, options);
    }

    public select(i: number, event?: MouseEvent): void {
        // let item = this.options.items.filter(x => !x.hidden)[i];
        let item = this.options.items[i];

        // Multiselect menu must always stay visible
        if (this.options.multiselect) {
            // Stay visible
            event.cancelBubble = true;

            // Toggle selection
            item.selected = !item.selected;

            // Handlers
            if (!item.selected) {
                this.onUnselect(item);
            }
            else {
                this.onSelect(item);
            }

            // Redraw
            this.update();
        }
        else {
            // Single item click hides self
            this.hide();

            // OnSelect handler
            this.onSelect(item);
        }
    }

    public hilight(i: number = -1): void {
        // Reset all items
        this.options.items.forEach((x) => {
            x.selected = false;
        });

        // Select only from visible items
        this.options.items.filter(x => !x.hidden).forEach((x, j) => {
            x.selected = j == i;
        });

        this.update();
    }

    public filter(text: string = ""): void {
        // Filter items
        this.options.items.forEach(x => {
            x.hidden = !x.label.toLowerCase().includes(text.toLowerCase());
        });

        // Update
        this.update();
    }

    public show(trigger: HTMLElement, x?: number, y?: number, target?: HTMLElement): void {
        // Already visible?
        if (this.isAttached()) {
            return;
        }

        // Attach to document
        this.attach(target);

        // Trigger element positions
        let rect = trigger ? trigger.getBoundingClientRect() : { left: x, top: y, width: 0, height: 0 };

        // Pivot point of the trigger
        let startX = 0;
        let startY = 0;

        // Get the trigger point
        if (this.options.start == "TopLeft") {
            startX = rect.left;
            startY = rect.top;
        }
        else if (this.options.start == "TopRight") {
            startX = rect.left + rect.width;
            startY = rect.top;
        }
        else if (this.options.start == "BottomLeft") {
            startX = rect.left;
            startY = rect.top +  + rect.height;
        }
        else {
            startX = rect.left + rect.width;
            startY = rect.top + rect.height;
        }

        // Menu position
        let left = 0;
        let top = 0;

        // Assign menu position
        if (this.options.anchor == "TopLeft") {
            left = startX + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "TopRight") {
            left = startX - this.element.offsetWidth + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "BottomLeft") {
            left = startX + document.body.scrollLeft;
            top = startY - this.element.offsetHeight + document.body.scrollTop;
        }
        else {
            left = startX - this.element.offsetWidth + document.body.scrollLeft;
            top = startY - this.element.offsetHeight + document.body.scrollTop;
        }

        // Move position by offset
        if (this.options.offset?.length == 2) {
            left += this.options.offset[0];
            top += this.options.offset[1];
        }

        // Set position to style
        this.element.style.left = `${left}px`;
        this.element.style.top = `${top}px`;

        // Add keyup listener on document to close self on escape key
        document.addEventListener("keyup", this.keyListener);

        // We need to cancel event propagation if click on itself
        this.element.addEventListener("mousedown", (e: MouseEvent) => {
            e.cancelBubble = true;
        });

        // We add document listener after some short delay because of event bubbling
        setTimeout(() => {
            document.addEventListener("mousedown", this.hideListener);
        }, TIMEOUT_DOCUMENT_LISTENER);
    }

    public key(e: KeyboardEvent): void {
        // ESC pressed so we can hide?
        if (e.key == "Escape") {
            this.hide();
        }
    }

    public hide(): void {
        // Detach from document
        this.detach();

        // Remove document listeners
        document.removeEventListener("keyup", this.keyListener);
        document.removeEventListener("mousedown", this.hideListener);
    }

}
