import "./digital-kiosks-subdomain.scss";
import * as template from "./digital-kiosks-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { DigitalKiosksSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ItemList } from "../../city/item-list/item-list";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { Helpers } from "../../../../hiyo/helpers";
import { DigitalKioskSessionsLabelLayer } from "../../../layers/services/digital-kiosk-sessions-label-layer";
import { DigitalKioskSessionsCircleLayer } from "../../../layers/services/digital-kiosk-sessions-circle-layer";

export class DigitalKiosksSubdomain extends CitySubdomain<DigitalKiosksSubdomainOptions> {

    constructor(context: InvipoContext, options?: DigitalKiosksSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Sessions",
                    itemClass: "DigitalKiosk",
                    layers: [
                        new DigitalKioskSessionsCircleLayer(context),
                        new DigitalKioskSessionsLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items || this.items),
                new ItemLabelLayer(this.context, items || this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public selectSymbol(symbol: string): void {
        this.selectDevices(this.items.filter(x => symbol == "Unknown" ? !x.data?.control?.state : x.data?.control?.state == symbol), [`enums.WarningLightState.${symbol}`]);
    }

    public async extraLoad(): Promise<void> {
        // Metrics name
        let metrics = Helpers.toKebabCase(this.metrics.name);

        this.legend = {
            description: `components.DigitalKiosksSubdomain.${metrics}Description`,
            range: {
                range: METRICS.services.kiosks[metrics].range,
                colors: METRICS.services.kiosks[metrics].colors,
                unit: METRICS.services.kiosks[metrics].unit,
                count: 0,
                value: 0
            },
            symbols: []
        }

        // Calculate average
        for (let item of this.items) {
            // No data?
            if (!item.data?.network || !item.data.network[metrics]) {
                continue;
            }

            this.legend.range.value += item.data.network[metrics];
            this.legend.range.count += 1;
        }

        // Set average and calculate relative position on range
        this.legend.range.value /= this.legend.range.count;
        this.legend.range.percent = Helpers.range(0, 100, METRICS.services.kiosks[metrics].range[0], METRICS.services.kiosks[metrics].range[1], this.legend.range.value);
    }

}
