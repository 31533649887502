import "./location-formatter/location-formatter.scss";
import "./percentage-formatter/percentage-formatter.scss";
import "./status-formatter/status-formatter.scss";
import "./malfunction-formatter/malfunction-formatter.scss";
import "./severity-formatter/severity-formatter.scss";
import "./plate-number/plate-number.scss";
import "./inline-button/inline-button.scss";
import "./wind-direction/wind-direction.scss";
import "./wind-measurement/wind-measurement.scss";
import "./surface-status/surface-status.scss";
import "./icon/icon.scss";
import "./label/label.scss";
import "./tag/tag.scss";
import "./flag/flag.scss";
import "./action/action.scss";
import "./json/json.scss";
import "./sign/sign.scss";
import "./snapshot/snapshot.scss";

import * as positionFormatter from "./location-formatter/location-formatter.hbs";
import * as percentageFormatter from "./percentage-formatter/percentage-formatter.hbs";
import * as statusFormatter from "./status-formatter/status-formatter.hbs";
import * as malfunctionFormatter from "./malfunction-formatter/malfunction-formatter.hbs";
import * as severityFormatter from "./severity-formatter/severity-formatter.hbs";
import * as plateNumber from "./plate-number/plate-number.hbs";
import * as inlineButton from "./inline-button/inline-button.hbs";
import * as windDirection from "./wind-direction/wind-direction.hbs";
import * as windMeasurement from "./wind-measurement/wind-measurement.hbs";
import * as surfaceStatus from "./surface-status/surface-status.hbs";
import * as icon from "./icon/icon.hbs";
import * as label from "./label/label.hbs";
import * as tag from "./tag/tag.hbs";
import * as flag from "./flag/flag.hbs";
import * as action from "./action/action.hbs";
import * as json from "./json/json.hbs";
import * as sign from "./sign/sign.hbs";
import * as snapshot from "./snapshot/snapshot.hbs";

import { Templates } from "hiyo/templates";

export namespace Partials {

    export function registerAll(): void {
        Templates.registerPartial("location-formatter", positionFormatter);
        Templates.registerPartial("percentage-formatter", percentageFormatter);
        Templates.registerPartial("status-formatter", statusFormatter);
        Templates.registerPartial("malfunction-formatter", malfunctionFormatter);
        Templates.registerPartial("severity-formatter", severityFormatter);
        Templates.registerPartial("plate-number", plateNumber);
        Templates.registerPartial("inline-button", inlineButton);
        Templates.registerPartial("wind-direction", windDirection);
        Templates.registerPartial("wind-measurement", windMeasurement);
        Templates.registerPartial("surface-status", surfaceStatus);
        Templates.registerPartial("icon", icon);
        Templates.registerPartial("label", label);
        Templates.registerPartial("tag", tag);
        Templates.registerPartial("flag", flag);
        Templates.registerPartial("action", action);
        Templates.registerPartial("json", json);
        Templates.registerPartial("sign", sign);
        Templates.registerPartial("snapshot", snapshot);
    }

}
