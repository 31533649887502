import "./korinthos-dashboard.scss";
import * as template from "./korinthos-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Dashboard } from "../dashboard/dashboard";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { KorinthosDashboardOptions } from "./types";

export class KorinthosDashboard extends Dashboard<KorinthosDashboardOptions> {

    constructor(context: InvipoContext, options?: KorinthosDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            }
        ];
    }

}
