import "./vehicle-browser.scss";
import * as template from "./vehicle-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { VehicleBrowserOptions } from "./types";
import { VehicleDetail } from "../vehicle-detail/vehicle-detail";
import { VehicleDetailOptions } from "../vehicle-detail/types";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { HanoiVehicleTrackerShortlist } from "../hanoi-vehicle-tracker-shortlist/hanoi-vehicle-tracker-shortlist";

export abstract class VehicleBrowser<T extends VehicleBrowserOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public shortlistToggled: boolean;

    // Components
    public table: FilterTable;
    public shortlist: HanoiVehicleTrackerShortlist;
    public detail: VehicleDetail;

    constructor(context: InvipoContext, options?: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
        // this.createShortlist();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public abstract createTable(): void;

    public createShortlist(): void {};

    public toggleShortlist(toggle?: boolean): void {
        let shortlist = this.query("div.shortlist");
        if (!shortlist) return;


        let hidden = shortlist.classList.contains("shortlist-hidden");

        if (toggle == true) {
            // Force open
            shortlist.classList.remove("shortlist-hidden");
            this.shortlistToggled = true;
        }
        else if (toggle == false) {
            // Force close
            shortlist.classList.add("shortlist-hidden");
            this.shortlistToggled = false;

            // remove selection from the toggle button
            const item = this.table.options.filter.items.find(x => x.name == "Map");
            if (item) {
                item.selected = false;
                this.query("div.partial-icon-selected")?.classList.remove("partial-icon-selected");
            }
        }
        else {
            // Toggle shortlist visibility
            shortlist.classList.toggle("shortlist-hidden", !hidden);
            this.shortlistToggled = !hidden;
        }

        this.shortlist?.map?.resize();
    }

    protected openDetail(data: any, title?: string): void {
        // Detail options
        let options: VehicleDetailOptions = {
            style: "Light",
            vehicle: data,
            title: title || "components.VehicleDetail.title",
            subtitle: data.item.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new VehicleDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.vehicle.id);
            this.shortlist?.map?.resize();
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "VehiclesCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(Object.keys(this.table.table.selectedRows).length > 0),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
