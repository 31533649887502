import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerItem } from "../components/common/drawer-menu/types";
import { MenuView } from "./menu-view";

export class Settings extends MenuView {

    public createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [
                {
                    name: "System",
                    title: "groups.System",
                    items: [
                        {
                            name: "NotificationManager",
                            label: "components.NotificationManager.title"
                        },
                        {
                            name: "UserManager",
                            label: "components.UserManager.title"
                        },
                        {
                            name: "TemplateManager",
                            label: "components.TemplateManager.title"
                        }
                    ]
                }
            ]
        });

        // Asset maangment
        if (this.context.user.boss || this.context.user.permissions?.includes("Administration")) {
            this.menu.options.groups.push({
                name: "Assets",
                title: "groups.Assets",
                items: [
                    {
                        name: "ItemEditor",
                        label: "components.ItemEditor.title",
                    },
                    {
                        name: "AreaManager",
                        label: "components.AreaManager.title",
                    },
                    {
                        name: "VehicleWatchlistSettings",
                        label: "components.VehicleWatchlistSettings.title",
                    }
                ]
            });
        }

        this.menu.onSelect = (item: DrawerItem) => {
            // Already selected?
            if (this.content?.name == item.name) {
                return;
            }

            // Change content component
            this.setContent(item.name);
        }
    }

}
