import "./vehicle-detail.scss";
import * as template from "./vehicle-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { VehicleDetailOptions } from "./types";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { LicenseDetail } from "../../home/license-detail/license-detail";
import { VehicleRegistryDetail } from "../vehicle-registry-detail/vehicle-registry-detail";
import { ContentView } from "invipo/views/content-view";
import { TextInput } from "muklit/components/text-input/text-input";
import { VideoDetail } from "../../common/video-detail/video-detail";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { Point } from "geojson";
import { Form } from "muklit/components/form/form";

export class VehicleDetail extends Detail<InvipoContext, VehicleDetailOptions> {

    // Components
    public tabs: Tabs;

    constructor(context: InvipoContext, options: VehicleDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "General",
                    label: "details.tabs.general",
                    content: "div.content-general",
                    selected: true
                }
            ]
        });

        // Has axle weight info?
        if (this.options.vehicle.measurement?.axles) {
            this.tabs.options.tabs.push({
                name: "Axles",
                label: "details.tabs.axles",
                content: "div.content-axles"
            });
        }
    }

    public openRegistry(): void {
        // Detail form
        let detail = new VehicleRegistryDetail(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true,
            lpn: this.options.vehicle.plate?.number
        });

        // Show form
        detail.attach();
    }

    public openImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public invalidate(skipLoad?: boolean) {
        // Recreate and register tabs
        this.createTabs();
        this.registerComponent(this.tabs);

        // Invalidation
        super.invalidate(skipLoad);
    }

    public async searchVehicle(): Promise<void> {
        const view: ContentView = <ContentView>this.context.application.currentView;
        view.setContent("HanoiVehicleSearchBrowser");

        const browser = (document.querySelector("[component=HanoiVehicleSearchBrowser]") as any)?.component;
        if (!browser) return;

        // Put photo into the filter
        const input = <TextInput>browser.table.form.options.fieldsets[0].fields[2];
        if (!input) return;

        // Set value
        input.setValue(this.options.vehicle.plate?.number);

        // Reload the data
        browser.table.load()
    }

    public openVideo(): void {
        // Get full item for the position
        let item = this.context.data.getItem(this.options.vehicle.item.id);

        // New image detail
        let detail = new VideoDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            timestamp: new Date(new Date(this.options.vehicle.timestamp).getTime() - 1000).toISOString(),
            position: (<Point>item.geometry.location).coordinates,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }
}
