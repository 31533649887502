import './wall-humans.scss';
import * as template from "./wall-humans.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { WallHumansOptions } from "./types";
import { ImageDetail } from "../../common/image-detail/image-detail";

const RELOAD_TIMEOUT = 10;

export class WallHumans extends MuklitComponent<InvipoContext, WallHumansOptions> {

    // Properties
    public humans: any[];
    public timer: any;

    constructor(context: InvipoContext, options?: WallHumansOptions) {
        super(context, template, options);
    }

    public openImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async load(): Promise<void> {
        // Clear timer
        clearTimeout(this.timer);

        // Show loader
        this.showLoader();

        // Get humans
        this.humans = (await this.context.invipo.getDataset("humans-data", "pageSize=30&sort=timestamp:desc")).data;

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Redraw
        this.update();

        // Reset after one minute
        this.timer = setTimeout(() => {
            this.load()
        }, RELOAD_TIMEOUT * 1000);

    }

}
