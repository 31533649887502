import "./filter.scss";
import * as template from "./filter.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { FilterAction, FilterItem, FilterOptions, FilterTab, FilterTag } from "./types";

export const REFRESH_INTERVAL = 5; // Seconds

export class Filter extends MuklitComponent<Context, FilterOptions> {

    // Properties
    public refresh: any;

    // Event handling methods
    public onFormToggle(toggled: boolean): void {};
    public onTabSelect(item: FilterTab): void {};
    public onItemSelect(item: FilterItem): void {};
    public onActionSelect(action: FilterAction): void {};
    public onTagRemove(tag: FilterTag): void {};
    public onTagsClear(): void {};
    public onReload(): void {};
    public onClear(): void {};

    constructor(context: Context, options: FilterOptions) {
        super(context, template, options);
    }

    public onDetach() {
        // Clear refresh interval
        clearInterval(this.refresh);
    }

    public toggleForm(): void {
        this.options.toggled = !this.options.toggled;

        // Update
        this.update();

        // OnFormToggle handler
        this.onFormToggle(this.options.toggled);
    }

    public selectTab(i: number): void {
        // Already selected?
        if (this.options.tabs[i].selected) {
            return;
        }

        // Unselect all
        //this.options.tabs.forEach(x => x.selected = false);

        // Selected tab
        //this.options.tabs[i].selected = true;

        // Update
        //this.update();

        // OnTabSelect handler
        this.onTabSelect(this.options.tabs[i]);
    }

    public removeTag(i: number): void {
        // Remember tag
        let tag = this.options.tags[i];

        // Remove tag
        this.options.tags.splice(i, 1);

        // Update
        this.update();

        // OnTagRemove handler
        this.onTagRemove(tag);
    }

    public selectItem(i: number): void {
        // Get action
        let item = this.options.items[i];

        // Item is selectable>
        if (item.selectable) {
            // Toggle selection
            item.selected = !item.selected;

            // Redraw
            this.update();
        }

        // Common Close item?
        if (item.name == "Close") {
            this.onClose();
        }
        // Common Reload item?
        else if (item.name == "Reload") {
            this.onReload();
        }
        // Common AutoRefresh item?
        else if (item.name == "AutoRefresh") {
            // Set interval
            if (item.selected) {
                this.refresh = setInterval(() => {
                    // Call relaod
                    this.onReload();
                }, REFRESH_INTERVAL * 1000);
            }
            // Clear interval
            else {
                clearInterval(this.refresh);
            }
        }
        // Common Print item?
        else if (item.name == "Print") {
            window.print();
        }
        // Other item
        else {
            this.onItemSelect(item);
        }
    }

    public selectAction(i: number): void {
        // Get action
        let action = this.options.actions[i];

        // Common Clear action?
        if (action.name == "Clear") {
            this.onClear();
        }
        else {
            this.onActionSelect(action);
        }
    }

    public reload(): void {
        // OnReload handler
        this.onReload();
    }

    public close(): void {
        // OnClose handler
        this.onClose();
    }
}
