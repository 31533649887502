import "./file-input.scss";
import * as template from "./file-input.hbs";
import { Context } from "hiyo/context";
import { FileInputOptions } from "./types";
import { Input } from "../input/input";
import { Log } from "../../../hiyo/log";

export class FileInput extends Input<Context, FileInputOptions> {

    // Properties
    public name: string;
    public size: number;

    constructor(context: Context, options: FileInputOptions) {
        super(context, template, options);

        // Set default value
        if (this.options.value) {
            this.setValue(this.options.value);
        }

    }

    public dragOver(e: DragEvent): void {
        // Stop propagation
        e.stopPropagation();
        e.preventDefault();
    }

    public dragEnter(e: DragEvent): void {
        // Add active class
        this.query("div.upload").classList.add("upload-active");
    }

    public dragLeave(e: DragEvent): void {
        // Remove active class
        this.query("div.upload").classList.remove("upload-active");
    }

    public drop(e: DragEvent): void {
        // prevent default action (dont open new tab)
        e.stopPropagation();
        e.preventDefault();

        // First file
        let file = e.dataTransfer.files[0];

        // Upload image
        this.upload(file);
    }

    public upload(file: File): void {
        // No file?
        if (!file) {
            Log.w(`ImageInput: No file dropped (empty data)`);
            return;
        }

        // Unsupported type?
        if (this.options.type && !this.options.type.split(",").includes(file.type)) {
            Log.w(`ImageInput: Unsupported image type (${file.type})`);

            this.setInvalid(true, "forms.validation.invalidFileFormat")
            return;
        }

        if (this.options.limit && file.size > this.options.limit * 1024) {
            Log.w(`ImageInput: File size exceeds limit (${file.size} > ${this.options.limit})`);

            this.setInvalid(true, "forms.validation.fileExceedsLimit");
            return;
        }

        // Assign file options
        this.name = file.name;
        this.size = file.size;

        // We nead reader to read a file
        let  reader = new FileReader();
        reader.addEventListener("load", (e: ProgressEvent<FileReader>): void => {
            // Add Base64 image data as input value
            this.options.value = e.target.result.toString();

            // Redraw
            this.update();
        });

        // Read file
        reader.readAsDataURL(file);
    }

    public select(): void {
        // Get input
        let input = this.query("input");

        // Force dialog to open
        input.click();
    }

    public clear(e: MouseEvent): void {
        // Stop propagation
        e.stopPropagation();
        e.preventDefault();

        // Reset options
        this.options.value = null;

        // Reset properties
        this.name = null;
        this.size = null;

        // Redraw
        this.update();
    }


    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Set empty name size and type
        if (typeof value === "string") {
            var type = this.options.value.split(',')[0].split(':')[1].split(';')[0];
            let data = this.options.value.split(',')[1];
            let size = (data.length * (3/4)) - data.split('=')[0].length;

            this.name = "";
            this.size = size;
        }

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }
    }

}
