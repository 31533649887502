import "./hanoi-vehicle-tracker-shortlist.scss";
import * as template from "./hanoi-vehicle-tracker-shortlist.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { HanoiVehicleTraqckerShortlistOptions } from "./types";
import { DataTable } from "muklit/components/data-table/data-table";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Templates } from "hiyo/templates";
import { VehicleLocation } from "../vehicle-appearance-report/types";
import { InvipoItem } from "invipo/clients/invipo-client/types";
import { CameraLocationCircleLayer } from "invipo/layers/safety/camera-location-circle-layer";
import { CameraLocationLabelLayer } from "invipo/layers/safety/camera-location-label-layer";
import { ItemLabelLayer } from "invipo/layers/infrastructure/item-label-layer";
import { GoogleRouteLineLayer } from "invipo/layers/traffic/google-route-line-layer";
import { Point } from "geojson";

export class HanoiVehicleTrackerShortlist extends Detail<InvipoContext, HanoiVehicleTraqckerShortlistOptions> {

    // Components
    public table: DataTable;
    public map: BasicMap;

    // Data
    public vehicles: any[] = [];

    // Event handling
    public onClear(): void {}
    public onDataChange(vehicles: any[]): void {}

    constructor(context: InvipoContext, options: HanoiVehicleTraqckerShortlistOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.table, "table");
        this.registerComponent(this.map, "map");
    }

    private createTable(): void {
        this.table = new DataTable(this.context, {
            style: "Light",
            type: "HoverOnly",
            size: "Short",
            height: "100%",
            rows: {
                id: "id"
            },
            autosort: true,
            columns: [
                {
                    name: "image",
                    type: "String",
                    property: "image",
                    label: "tables.columns.image",
                    width: 104,
                    formatter: (value: any, data: any): string => {
                        if (!data.images || data.images.length == 0) return "";

                        // Return image tag
                        return Templates.renderPartial("snapshot", {
                            url: `${this.context.options.host}/download${data.images[0].url}`,
                            height: 48
                        });
                    },
                },
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.timestamp",
                    minWidth: 160,
                    sortable: true,
                    selected: true,
                    descendent: true
                },
                {
                    name: "plate.number",
                    type: "String",
                    property: "plate.number",
                    label: "tables.columns.plate",
                    formatter: (value: any, data: any): string => {
                        if (!value) {
                            return;
                        }
                        return `<div class="cell">${Templates.renderPartial("plate-number", data.plate)}</div>`;
                    },
                    width: 100
                },
                {
                    name: "action.remove.from.shortlist",
                    type: "String",
                    property: "action.remove.from.shortlist",
                    label: "",
                    width: 40,
                    formatter: (value: any, data: any): string => {
                        return `<div class="remove" onclick="component.parent.removeVehicle('${data.id}',event)"></div>`;
                    }
                }
            ],
        });
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview.center,
            zoom: this.context.options.overview.zoom,
            minZoom: 2,
            maxZoom: 21
        });
    }

    public clear():void {
        this.vehicles = [];
        this.table.setData(this.vehicles);
        this.updateMap();
        this.onClear();
    }

    public onAttach(): void {
        super.onAttach();

        this.table.setData(this.vehicles);
        this.updateMap();
    }

    public setData (vehicles: any[]): void {
        if (vehicles.length == 0 && this.vehicles.length == 0) {
            return;
        }

        this.vehicles = vehicles;

        if (this.isAttached()) {
            this.table.setData(this.vehicles);
            this.updateMap();
        }
    }

    public removeVehicle (id: string, event: Event): void {
        event.preventDefault()
        event.stopPropagation();

        this.vehicles = this.vehicles.filter(x => x.id != id);

        this.table.setData(this.vehicles);
        this.updateMap();

        this.onDataChange(this.vehicles);
    }

    private updateMap(): void {
        // sort by timestamp
        this.vehicles.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);

        // We need items for their locations
        const items: InvipoItem[] = []

        let locations: VehicleLocation[] = [];
        let routes: Record<string, VehicleLocation[]> = {};

        for (let row of this.vehicles) {
            // Find corresponding item
            // let item = items.find(x => x.id == row.item.id);
            let item = this.context.data.getItem(row.item.id);

            // Add to list of items for map
            if (!items.some(x => x.id == item.id)) {
                items.push(item);
            }

            // Get item location
            let location = locations.find(x => x.name == item.name);

            // Already exists?
            if (location) {
                location.count += 1;
            }
            // New location
            else {
                locations.push({
                    name: item.name,
                    geometry: item.geometry.location,
                    direction: item.meta.direction || 0,
                    count: 1
                });
            }

            // Get route
            let route = routes[row.plate.number];
            let last = route ? route[route.length - 1] : null;
            if (route && last.name != item.name) {
                route.push({
                    name: item.name,
                    geometry: item.geometry.location,
                    direction: item.meta.direction || 0,
                });
            }
            // Start new Route
            else if (!route) {
                routes[row.plate.number] = [{
                    name: item.name,
                    geometry: item.geometry.location,
                    direction: item.meta.direction || 0,
                }];

            }

        }

        // Clean the previous layers
        this.map.removeLayers();

        // Add item layers
        this.map.addLayer(new CameraLocationCircleLayer(this.context, locations));
        this.map.addLayer(new CameraLocationLabelLayer(this.context, locations));
        this.map.addLayer(new ItemLabelLayer(this.context, items));

        // Show individual vehicle routes
        for (const route of Object.values(routes)) {
            if (route.length > 1) {
                this.map.addLayer(new GoogleRouteLineLayer(this.context, route.map(x => (<Point>x.geometry).coordinates)));
            }
        }
    }
}
