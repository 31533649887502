import { Templates } from "hiyo/templates";
import { ItemSelect } from "../../common/item-select/item-select";
import { VehicleSelect } from "../../common/vehicle-select/vehicle-select";
import { VehicleBrowser } from "../vehicle-browser/vehicle-browser";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterItem, FilterTab } from "muklit/components/filter/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { HanoiVehicleCollisionBrowserOptions } from "./types";
import { TextInput } from "muklit/components/text-input/text-input";
import { RangeInput } from "muklit/components/range-input/range-input";
import { HiyoEvent } from "../../../../hiyo/event-broker";
import { ContentView } from "../../../views/content-view";

export class HanoiVehicleCollisionBrowser extends VehicleBrowser<HanoiVehicleCollisionBrowserOptions> {

    public onCreate(): void {
        // Call self
        super.onCreate();

        // Route handling
        this.context.broker.subscribe(this, ["RouteChanged"]);
    }

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/vehicles-data`,
            empty: true,
            search: {},
            http: this.context.invipo.http,
            filter: {
                title: "components.VehicleBrowser.title",
                tabs: [
                    {
                        name: "HanoiVehicleSearchBrowser",
                        label: "components.HanoiVehicleSearchBrowser.title",
                    },
                    {
                        name: "HanoiVehicleCollisionBrowser",
                        label: "components.HanoiVehicleCollisionBrowser.title",
                        selected: true
                    },
                    {
                        name: "HanoiVehicleTrackerBrowser",
                        label: "components.HanoiVehicleTrackerBrowser.title",
                    },
                    {
                        name: "WatchlistVehicleManager",
                        label: "components.WatchlistVehicleManager.title",
                    }
                ],
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                toggled: true,
                togglable: false
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                value: this.context.application.state.componentName == this.name && this.context.application.state.from ? { from: this.context.application.state.from, to: this.context.application.state.to } : null,
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                value: this.context.application.state.componentName == this.name && this.context.application.state.itemId || null,
                                placeholderText: "forms.placeholders.all",
                                distinct: "VehiclesData",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
              page: 1
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "image",
                        type: "String",
                        property: "image",
                        label: "tables.columns.image",
                        width: 104,
                        formatter: (value: any, data: any): string => {
                            // Image url for source and tooltip
                            let url = `${this.context.options.host}/download${data.images[0].url}`;

                            // Return image tag
                            return Templates.renderPartial("snapshot", {
                                url: `${this.context.options.host}/download${data.images[0].url}`,
                                height: 48
                            });
                        },
                    },
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true
                    },
                    {
                        name: "plate.number",
                        type: "String",
                        property: "plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "classification.category",
                        type: "Number",
                        property: "classification.category",
                        formatter: (value: any, data: any): string => {
                            if (value == null) {
                                return null;
                            }
                            return `<div class="cell cell-ellipsis">${this.context.config.categories.find(x => x.id == value)?.name || value}</div>`;
                        },
                        label: "tables.columns.category",
                        width: 140,
                        sortable: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        width: 300,
                        sortable: true,
                        ellipsis: true
                    },
                    /*{
                        name: "lane",
                        type: "String",
                        property: "lane",
                        label: "tables.columns.lane",
                        width: 140,
                        sortable: true,
                        ellipsis: true
                    },*/
                    {
                        name: "item.location",
                        type: "String",
                        property: "item.location",
                        label: "tables.columns.location",
                        minWidth: 260,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Change component
        this.table.onTabSelect = (item: FilterTab) => {
            // Get current view as content view
            let view: ContentView = <ContentView>this.context.application.currentView;

            // Change view
            view.setContent(item.name);
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public async onHandle(event: HiyoEvent) {
        // Routing is for me?
        if (event.payload?.componentName == this.name) {
            // Set interval
            this.table.form.setValue("interval", {
                from: event.payload.from,
                to: event.payload.to
            });

            // Set item id
            this.table.form.setValue("item.id", event.payload.itemId);

            // Reload table data
            await this.table.load();
        }
    }
}
