import { ItemSelect } from "../../common/item-select/item-select";
import { HumanBrowser } from "../human-browser/human-browser";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { LunaHumanBodyBrowserOptions } from "./types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { FilterItem, FilterTab } from "../../../../muklit/components/filter/types";
import { ContentView } from "../../../views/content-view";
import { LunaHumanImageInput } from "../luna-human-image-input/luna-human-image-input";
import { CameraLocationCircleLayer } from "invipo/layers/safety/camera-location-circle-layer";
import { CameraLocationLabelLayer } from "invipo/layers/safety/camera-location-label-layer";
import { GoogleRouteLineLayer } from "invipo/layers/traffic/google-route-line-layer";
import { Point } from "geojson";
import { VehicleLocation } from "invipo/components/traffic/vehicle-appearance-report/types";
import { Templates } from "hiyo/templates";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { METRICS } from "invipo/components/city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";
import { TextInput } from "muklit/components/text-input/text-input";
import { Autocomplete } from "muklit/components/autocomplete/autocomplete";

import './luna-human-body-browser.scss';

export class LunaHumanBodyBrowser extends HumanBrowser<LunaHumanBodyBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/externals/luna/matcher/bodies`,
            http: this.context.invipo.http,
            empty: true,
            filter: {
                title: "components.HumanBrowser.title",
                tabs: [
                    {
                        name: "HumanSearchBrowser",
                        label: "components.HumanSearchBrowser.title",
                    },
                    {
                        name: "LunaHumanFaceBrowser",
                        label: "components.LunaHumanFaceBrowser.title"
                    },
                    {
                        name: "LunaHumanBodyBrowser",
                        label: "components.LunaHumanBodyBrowser.title",
                        selected: true
                    },
                    {
                        name: "WatchlistHumanManager",
                        label: "components.WatchlistHumanManager.title",
                    }
                ],
                items: [
                    {
                        name: "Map",
                        label: "labels.map",
                        selectable: true,
                        selected: false
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                toggled: true,
                togglable: false
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new LunaHumanImageInput(this.context, {
                                type: "Body",
                                style: "Light",
                                name: "sdkDescriptor",
                                required: true,
                                height: 400
                            }),
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "HumansData",
                                items: [],
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "threshold",
                                label: "forms.fields.threshold",
                                format: "Number",
                                placeholderText: "80 %", // default value onthe backend
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "gender",
                                label: "forms.fields.gender",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanGender"))
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "apparentGender",
                                label: "forms.fields.apparentGender",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanGender")),
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "ethnicity",
                                label: "forms.fields.ethnicity",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.Ethnicity")),
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "emotion",
                                label: "forms.fields.emotion",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanEmotion")),
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "mask",
                                label: "forms.fields.mask",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanMask")),
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "headwear",
                                label: "forms.fields.headwear",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HeadwearState")),
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "headwearColor",
                                label: "forms.fields.headwearColor",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.AccessoryColor")),
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "backpack",
                                label: "forms.fields.backpack",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.BackpackState")),
                            }),
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "eventId"
                },
                autosort: true,
                columns: [
                    {
                        name: "image",
                        type: "String",
                        property: "image",
                        label: "tables.columns.image",
                        width: 84,
                        formatter: (value: any, data: any): string => {
                            // Return image tag
                            return Templates.renderPartial("snapshot", {
                                url: data.images[0].content,
                                height: 60
                            });
                        },
                    },
                    {
                        name: "similarity",
                        type: "String",
                        property: "similarity",
                        label: "tables.columns.match",
                        width: 60,
                        sortable: true,
                        selected: true,
                        descendent: true,
                        formatter: (value: any): string => {
                            value = Math.round(value);

                            let color: string;
                            for (let i = 0; i < METRICS.safety.luna.confidence.interval.length; i++) {
                                const interval = METRICS.safety.luna.confidence.interval[i];

                                if (value >= interval[0] && value < interval[1]) {
                                    color = METRICS.safety.luna.confidence.colors[i];
                                    break;
                                }
                            }

                            return `
                                <div class="cell">
                                    <div style="
                                        width:24px;
                                        height:24px;
                                        white-space: nowrap;
                                        background:${color};
                                        padding: 0 4px;
                                        font-size: 12px;
                                        color: #fff;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 4px;
                                    ">${Helpers.toNumber(value, 1)}%</div>
                                </div>`;
                        }
                    },
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                    },
                    {
                        name: "gender",
                        type: "String",
                        property: (data: any) => {
                            return data.gender ? this.context.locale.getMessage(`enums.HumanGender.${data.gender}`) : "";
                        },
                        label: "tables.columns.gender",
                        width: 100,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "age",
                        type: "Number",
                        property: "age",
                        label: "tables.columns.age",
                        align: "Right",
                        width: 60,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Change component
        this.table.onTabSelect = (item: FilterTab) => {
            // Get current view as content view
            let view: ContentView = <ContentView>this.context.application.currentView;

            // Change view
            view.setContent(item.name);
        }



        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Map") {
                // Toggle map
                this.toggleMap(item.selected);
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview.center,
            zoom: this.context.options.overview.zoom,
            minZoom: 2,
            maxZoom: 21
        });

        this.registerComponent(this.map, "map");

        // Open map by default
        this.toggleMap(true);
    }

    public async selectRow(rows: any[]): Promise<void> {
        // sort by timestamp
        rows.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);

        // We need items for their locations
        const items = this.context.data.getItems();

        let locations: VehicleLocation[] = [];

        for (let row of rows) {
            // Find corresponding item
            let item = items.find(x => x.id == row.item.id);
            if (!item || !item.geometry) continue;

            // Get item location
            let location = locations.find(x => x.name == item.name);

            // Already exists?
            if (location) {
                location.count += 1;
            }
            // New location
            else {
                locations.push({
                    name: item.name,
                    geometry: item.geometry.location,
                    direction: item.meta.direction || 0,
                    count: 1
                });
            }
        }

        // Clean the previous layers
        this.map.removeLayers();

        // Add item layers
        this.map.addLayer(new CameraLocationCircleLayer(this.context, locations));
        this.map.addLayer(new CameraLocationLabelLayer(this.context, locations));

        // Show individual vehicle routes
        if (locations.length > 1) {
            this.map.addLayer(new GoogleRouteLineLayer(this.context, locations.map(x => (<Point>x.geometry).coordinates)));
        }
    }
}
