import "./select.scss";
import * as template from "./select.hbs";
import { Context } from "hiyo/context";
import { SelectOptions } from "./types";
import { OverflowMenu } from "../overflow-menu/overflow-menu";
import { MenuItem } from "../overflow-menu/types";
import { Input } from "../input/input";
import { InputValue } from "../input/types";

const CLASS_ACTIVE = "muklit-select-active";

export class Select<T extends Context = Context, U extends SelectOptions = SelectOptions> extends Input<T, U> {

    // Components
    public menu: OverflowMenu;

    constructor(context: T, options: U) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();

        // String value rearrangement
        if (this.options.value && typeof this.options.value == "string") {
            let item = this.options.items.find(x => x.name == this.options.value);

            // Item found?
            if (item) {
                this.options.value = {};
                this.options.value[item.name] = item.label;
            }
        }

        // Disable select if no items
        this.options.disabled = this.options.items.length == 0;
    }

    private createMenu(): void {
        // Create component
        this.menu = new OverflowMenu(this.context, {
            style: this.options.style,
            items: this.options.items,
            start: "BottomLeft",
            anchor: "TopLeft",
            offset: [0, 4],
            multiselect: this.options.multiselect
        })

        // Reset value when selected from menu
        this.menu.onSelect = (item: MenuItem) => {
            let value: InputValue;

            if (this.options.multiselect) {
                value = { ...<InputValue>this.options.value };
            }
            else {
                value = {};
            }

            // Add new value
            value[item.name] = item.label;

            // Set new value
            this.setValue(value);

            // OnSubmit handle
            this.onSubmit();
        }

        // Reset value when selected from menu
        this.menu.onUnselect = (item: MenuItem) => {
            // Unselect works only for multiselect;
            if (!this.options.multiselect) {
                return;
            }

            // Multiple values allowed
            let values: InputValue = { ...<InputValue>this.options.value };

            // Remove existing value
            delete values[item.name];

            // Set null if values are empty
            if (Object.keys(values).length == 0) {
                values = null;
            }

            // Set multiple values
            this.setValue(values);

            // OnSubmit handle
            this.onSubmit();
        }

        // Add active state
        this.menu.onAttach = () => {
            this.element?.classList.add(CLASS_ACTIVE);
        }

        // Remove active state
        this.menu.onDetach = () => {
            this.element?.classList.remove(CLASS_ACTIVE);
        }
    }

    public select(): void {
        // Empty items?
        if (this.options.items.length == 0) {
            return;
        }

        // Select menu items on multiselect
        if (this.options.multiselect) {
            for (let item of this.options.items) {
                item.selected = this.options.value != null && (<InputValue>this.options.value)[item.name] != null;
            }
        }

        // Make right position
        this.menu.options.width = this.element.offsetWidth;

        // Reassign items
        this.menu.options.items = this.options.items;

        // Show menu
        this.menu.show(null, undefined, undefined, this.element);
    }

    public setValue(value: string | InputValue) {
        // Sets value to options
        this.options.value = value;

        // Set value options
        if (value == null) {
            this.options.value = null;
        }
        else if (typeof value == "object") {
            this.options.value = value;
        }
        else {
            // If value is plain string, we try to find first item fitting the name
            let item = this.options.items.find(x => x.name == value);

            // Item found?
            if (item) {
                this.options.value = {};
                this.options.value[value] = item.label;
            }
            // Otherwise we will assign plain value
            else {
                this.options.value = value;
            }
        }

        // Reset invalid state and messageText once we select new value
        this.options.invalid = null;
        this.options.messageText = null;

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }

        // OnClear handler
        if (value == null) {
            this.onClear();
        }
    }

    public async load(): Promise<void> {
        // Disable if select has no items
        this.setDisabled(!this.options.items?.length);
    }
}
