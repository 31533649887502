import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerGroup } from "../components/common/drawer-menu/types";
import { MenuView } from "./menu-view";

export const PERMISSION_MAP: any = {
    HanoiViolationReviewBrowser: "ViolationReview",
    HanoiViolationProcessBrowser: "ViolationProcessing",
    HanoiViolationNoticeBrowser: "ViolationNotification",
    HanoiViolationReportBrowser: "ViolationManagement",
    HanoiViolationDecisionBrowser: "ViolationLegalResolution",
    HanoiViolationUnsubstantiatedBrowser: "ViolationDismissal",
    HanoiViolationResolvedBrowser: "ViolationResolved",
}

export class Enforcement extends MenuView {

    public createMenu(): void {
        // Menu groups
        let groups: DrawerGroup[] = [];

        // Build groups and items
        for (let g of Object.keys(this.context.options.enforcement)) {
            // New group based on domain name
            let group: DrawerGroup = {
                name: g,
                title: `groups.${g}`,
                items: []
            }

            // Add data
            for (let data of this.context.options.enforcement[g]) {
                // Permission check
                if (this.context.user.boss || (PERMISSION_MAP[data] && this.context.user.permissions?.includes(PERMISSION_MAP[data]))) {
                    group.items.push({
                        name: data,
                        label: `components.${data}.title`
                    })
                }
            }

            // Add only non-empty group
            if (group.items.length > 0) {
                groups.push(group);
            }
        }

        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: groups
        });
    }

}
