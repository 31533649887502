import "./watchlist-vehicle-detail.scss";
import * as template from "./watchlist-vehicle-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { WatchlistVehicleDetailOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { ContentView } from "invipo/views/content-view";
import { TextInput } from "muklit/components/text-input/text-input";
import { WatchlistVehicleForm } from "../watchlist-vehicle-form/watchlist-vehicle-form";

export class WatchlistVehicleDetail extends Detail<InvipoContext, WatchlistVehicleDetailOptions> {

    // Properties
    public vehicle: any;

    // Components
    public map: BasicMap;
    public layer: WatchlistVehicleDetailOptions;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: WatchlistVehicleDetailOptions) {
        super(context, template, options);
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public async load(): Promise<void> {
        // Load item
        this.vehicle = await this.context.invipo.getManagement(`watchlists/vehicles/${this.options.vehicle.id}`);

        // Redraw with all components
        this.update();
    }

    public async searchVehicle(): Promise<void> {
        const view: ContentView = <ContentView>this.context.application.currentView;
        view.setContent("HanoiVehicleSearchBrowser");

        const browser = (document.querySelector("[component=HanoiVehicleSearchBrowser]") as any)?.component;
        if (!browser) return;

        // Put photo into the filter
        const input = <TextInput>browser.table.form.options.fieldsets[0].fields[2];
        if (!input) return;

        // Set value
        input.setValue(this.vehicle.lpn);

        // Reload the data
        browser.table.load()
    }

    public openForm (): void {
        const form = new WatchlistVehicleForm(this.context, {
            vehicle: this.vehicle,
            style: "Light",
            title: "components.WatchlistVehicleForm.title",
            overlay: true,
            closable: true
        });

        form.attach();

        form.onSubmit = async () => {
            this.invalidate();
            this.onDetailUpdate();
        }
    }

    public openImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.WatchlistVehicleForm.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }
}
