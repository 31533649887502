import { Templates } from "hiyo/templates";
import { ItemSelect } from "../../common/item-select/item-select";
import { VehicleSelect } from "../../common/vehicle-select/vehicle-select";
import { VehicleBrowser } from "../vehicle-browser/vehicle-browser";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterItem, FilterTab } from "muklit/components/filter/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { HanoiVehicleSearchBrowserOptions } from "./types";
import { TextInput } from "muklit/components/text-input/text-input";
import { RangeInput } from "muklit/components/range-input/range-input";
import { ContentView } from "../../../views/content-view";
import { Autocomplete } from "muklit/components/autocomplete/autocomplete";

export class HanoiVehicleSearchBrowser extends VehicleBrowser<HanoiVehicleSearchBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/vehicles-data`,
            search: {},
            http: this.context.invipo.http,
            filter: {
                title: "components.VehicleBrowser.title",
                tabs: [
                    {
                        name: "HanoiVehicleSearchBrowser",
                        label: "components.HanoiVehicleSearchBrowser.title",
                        selected: true
                    },
                    {
                        name: "HanoiVehicleCollisionBrowser",
                        label: "components.HanoiVehicleCollisionBrowser.title"
                    },
                    {
                        name: "HanoiVehicleTrackerBrowser",
                        label: "components.HanoiVehicleTrackerBrowser.title",
                    },
                    {
                        name: "WatchlistVehicleManager",
                        label: "components.WatchlistVehicleManager.title",
                    }
                ],
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                toggled: true,
                togglable: false
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                value: this.context.application.state.componentName == this.name && this.context.application.state.from ? {
                                    from: this.context.application.state.from,
                                    to: this.context.application.state.to
                                } : null,
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                value: this.context.application.state.componentName == this.name && this.context.application.state.itemId || null,
                                placeholderText: "forms.placeholders.all",
                                distinct: "VehiclesData",
                                items: [],
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "plate.number",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "classification.category",
                                label: "forms.fields.category",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                                multiselect: true
                            }),
                            /*new VehicleSelect(this.context, {
                                style: "Light",
                                type: "Lane",
                                name: "lane",
                                label: "forms.fields.lane",
                                placeholderText: "forms.placeholders.all",
                                items: [],
                                multiselect: true
                            }),*/
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "brand",
                                label: "forms.fields.brand",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("VehiclesData", "brand")),
                                multiselect: true
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "model",
                                label: "forms.fields.model",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("VehiclesData", "model")),
                                multiselect: true
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "color",
                                label: "forms.fields.color",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("VehiclesData", "color")),
                                multiselect: true
                            })
                        ]
                    },
                    {
                        name: "Speed",
                        type: "Inline",
                        fields: [
                            new TextInput(this.context, {
                                style: "Light",
                                name: "measurement.speed.min",
                                label: "forms.fields.speedMin",
                                placeholderText: "forms.placeholders.speedMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "measurement.speed.max",
                                label: "forms.fields.speedMax",
                                placeholderText: "forms.placeholders.speedMax"
                            })
                        ]
                    },
                    {
                        name: "Watchlist",
                        fields: [
                            new Select(this.context, {
                                style: "Light",
                                name: "watchlist",
                                label: "forms.fields.watchlist",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "watchlist")),
                                multiselect: true
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "strategy",
                                label: "forms.fields.strategy",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("components.VehicleBrowser.strategy"))
                            })
                        ]
                    }
                ]
            },
            pagination: {
              page: 1
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "plate.number",
                        type: "String",
                        property: "plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "classification.category",
                        type: "Number",
                        property: "classification.category",
                        formatter: (value: any, data: any): string => {
                            if (value == null) {
                                return null;
                            }
                            return `<div class="cell cell-ellipsis">${this.context.config.categories.find(x => x.id == value)?.name || value}</div>`;
                        },
                        label: "tables.columns.category",
                        width: 140,
                        sortable: true
                    },
                    /*{
                        name: "lane",
                        type: "String",
                        property: "lane",
                        label: "tables.columns.lane",
                        width: 140,
                        sortable: true,
                        ellipsis: true
                    }*/
                    {
                        name: "item.location",
                        type: "String",
                        property: "item.location",
                        label: "tables.columns.location",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    },
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Change component
        this.table.onTabSelect = (item: FilterTab) => {
            // Get current view as content view
            let view: ContentView = <ContentView>this.context.application.currentView;

            // Change view
            view.setContent(item.name);
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }
}
