import "./video-detail.scss";
import * as template from "./video-detail.hbs";
import { Detail } from "muklit/components/detail/detail";
import { InvipoContext } from "../../../context/invipo-context";
import { VideoDetailOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { IFrameElementContainer } from "html2canvas/dist/types/dom/replaced-elements/iframe-element-container";
import { Point } from "geojson";
import { Log } from "../../../../hiyo/log";

export class VideoDetail extends Detail<InvipoContext, VideoDetailOptions> {

    // Properties
    public items: InvipoItem[];

    constructor(context: InvipoContext, options: VideoDetailOptions) {
        super(context, template, options);
    }

    public setStream(i: number): void {
        // Construct stream, start 1s before
        let stream = this.items[i].meta.stream.iframe.replace("{{timestamp}}", new Date(new Date(this.options.timestamp).getTime() - 1000).toISOString());

        // Remove selected classes
        this.queryAll("div.preview div.image").forEach((e: HTMLElement) => {
            e.classList.remove("image-selected");
        });

        // Select current
        this.queryAll("div.preview div.image")[i].classList.add("image-selected");

        // Change image source
        this.query<HTMLIFrameElement>("div.video iframe").src = stream;
    }

    async load(): Promise<void> {
        // No position?
        /*if (!this.options.position) {
            return;
        }*/

        // Get all camera items
        this.items = this.context.data.getItems({
            class: "Camera"
        });

        // Filter out only cameras with stream
        this.items = this.items.filter(x => x.meta.stream.iframe != null);

        // Filter out cameras nearby
        this.items = this.items.filter(x => (Math.abs((<Point>x.geometry.location).coordinates[0] - this.options.position[0]) <= 0.0001) && (Math.abs((<Point>x.geometry.location).coordinates[1] - this.options.position[1]) <= 0.0001));

        // No items with stream?
        if (this.items.length == 0) {
            Log.w("VideoDetail: No items with stream found");
            return;
        }
        console.info(this.items);

        // Relaod
        this.update();

        // Select first
        this.setStream(0);
    }
}
