import "./watchlist-vehicle-form.scss";
import * as template from "./watchlist-vehicle-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { WatchlistVehicleFormOptions } from "./types";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Autocomplete } from "muklit/components/autocomplete/autocomplete";
import { TextArea } from "muklit/components/text-area/text-area";
import { FileInput } from "muklit/components/file-input/file-input";

export class WatchlistVehicleForm extends Detail<InvipoContext, WatchlistVehicleFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: WatchlistVehicleFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // New vehicle form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Row",
                    fields: [
                        new Select(this.context, {
                            style: "Light",
                            name: "watchlist",
                            label: "forms.fields.watchlist",
                            width: 284,
                            items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "watchlist")),
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "newWatchlist",
                            label: "forms.fields.newWatchlist",
                            width: 284,
                            placeholderText: "forms.placeholders.addNewWatchlist",
                            bright: true
                        })
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "lpn",
                            label: "forms.fields.plateNumber",
                            width: 284,
                            placeholderText: "forms.placeholders.plateNumber",
                            required: true,
                            bright: true
                        }),
                        new Autocomplete(this.context, {
                            style: "Light",
                            name: "color",
                            label: "forms.fields.color",
                            width: 284,
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.VehicleColor")),
                            bright: true
                        })
                    ]
                }, {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "brand",
                            label: "forms.fields.brand",
                            width: 284,
                            placeholderText: "forms.placeholders.brand",
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "model",
                            label: "forms.fields.model",
                            width: 284,
                            placeholderText: "forms.placeholders.model",
                            bright: true
                        })
                    ]
                }, {
                    name: "Row",
                    fields: [
                        new Autocomplete(this.context, {
                            style: "Light",
                            name: "category",
                            label: "forms.fields.category",
                            width: 284,
                            height: 40,
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                            bright: true
                        }),
                        new FileInput(this.context, {
                            style: "Light",
                            name: "image",
                            label: "forms.fields.image",
                            width: 284,
                            height: 40,
                            type: "image/png,image/jpeg",
                        }),
                    ]
                }, {
                    name: "Row",
                    fields: [
                        new TextArea(this.context, {
                            style: "Light",
                            name: "note",
                            label: "forms.fields.note",
                            width: 584, // 284,
                            rows: 1,
                            placeholderText: "forms.placeholders.note",
                            bright: true
                        }),
                    ]
                }
            ]
        });

        // Sets vehicle data (update mode)
        this.form.setData(this.options.vehicle);
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Add new watchlist was created
        if (data.newWatchlist?.length) {
            data.watchlist = data.newWatchlist;
        }
        delete data.newWatchlist;

        // parse category number
        if (typeof data.category == "string") {
            data.category = parseInt(data.category);
        }

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            if (this.options.vehicle?.id) {
                await this.context.invipo.putManagement(`watchlists/vehicles/${this.options.vehicle.id}`, data);
            }
            else {
                await this.context.invipo.postManagement("watchlists/vehicles", data);
            }
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
