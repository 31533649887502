import "./item-map.scss";
import * as template from "./item-map.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { HradecKraloveOrthophotoLayer } from "../../../layers/custom/hradec-kralove-orthophoto-layer";
import { GoogleOrthophotoLayer } from "../../../layers/custom/google-orthophoto-layer";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { ItemMapOptions } from "./types";
import { Feature, GeoJsonProperties, Point } from "geojson";
import { Log } from "hiyo/log";
import { COMPONENT_STORE } from "../../../store";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { CityCard } from "../../city/city-card/city-card";

export class ItemMap extends Detail<InvipoContext, ItemMapOptions> {

    // Components
    public map: BasicMap;
    public card: CityCard;

    // Event handling methods
    public constructor(context: InvipoContext, options: ItemMapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
    }

    public createMap () {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: this.options.minZoom || 0,
            maxZoom: this.options.maxZoom || 22,
            layers: this.options.layers
        });

        // Check custom layers on style loaded
        this.map.onMapLoad = () => {
            // HradecKraloveOrthophoto style?
            if (this.map.options.style == "HradecKraloveOrthophoto") {
                // Project custom orthophoto raster layer that must be added separately outside the layer management as a first layer
                this.map.mapboxMap.addLayer(new HradecKraloveOrthophotoLayer(this.context).options.layer, this.map.mapboxMap.getStyle().layers[0].id);
            }
            // GoogleOrthophoto style?
            if (this.map.options.style == "GoogleOrthophoto") {
                // Google orthophoto imaginery
                this.map.mapboxMap.addLayer(new GoogleOrthophotoLayer(this.context).options.layer, this.map.mapboxMap.getStyle().layers[0].id);
            }
        }

        // Select detail
        // This must be binded to "mousedown" event as it does not break detail hiding
        this.map.onFeatureMouseDown = async (layer: string, feature: Feature, event?: MouseEvent) => {
            // Open card
            if (feature.properties.card) {
                this.openCard(feature.properties, event);
            }
        }

        // Register component
        this.registerComponent(this.map, "map");
    }


    public openCard(properties: GeoJsonProperties, event?: MouseEvent): void {
        // Missing cardId?
        if (!properties.cardId) {
            Log.w(`${this.id} has no cardId in options`);
        }

        // Card is already displayed?
        if (this.card?.isAttached() && properties.detail && this.card.options.cardId == properties.cardId) {
            return;
        }

        Log.i(`${this.id} opens card ${properties.card}`);

        // Create detail
        this.card = new COMPONENT_STORE[properties.card](this.context, {
            style: "Light",
            ...properties,
            //closable: true // Not closable for now
        });

        // Handle item selection
        this.card.onItemSelect = (item: InvipoItem, card?: string) => {
            // New set of properties
            properties = {
                type: properties.type,
                data: properties.data,
                card: card || properties.card,
                detail: "CityDetail",
                cardId: item.id,
                itemId: item.id,
                itemName: item.name,
                itemClass: item.class
            }

            // Repin new detail to keep all bindings, callbacks etc.
            this.openCard(properties, event);
        }

        // Show detail
        this.card.show(event?.x || (this.element.offsetWidth / 2 + 56), event?.y || (this.element.offsetHeight / 2), 110);
    }

    public async load(): Promise<void> {
        // Item id not defined?
        if (!this.options.itemId) {
            return;
        }

        // Laod item
        let item = this.context.data.getItem(this.options.itemId);

        // Center and zoom map
        this.map.setCenter((<Point>item.geometry.location).coordinates);
        this.map.setZoom(18.5);
    }

}
