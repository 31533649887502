import {Log, LogColor} from "./log";

export const DEFAULT_OPTIONS: HttpOptions = {
    dataType: "application/json",
    contentType: "application/json",
    timeout: 2000
}

const CACHE_DELAY = 1; // seconds

export class Http {

    // Properties
    public options: HttpOptions;
    public cache: { [key: string]: CacheEntry };
    private static number = 0;

    // Event handling methods
    public onError(response: any): void {}
    public onResponse(response: any): void {}
    public onTimeout(timeout: number): void {}

    constructor(options?: HttpOptions) {
        this.options = {
            ...DEFAULT_OPTIONS,
            ...options
        };

        // Create empty cache
        this.cache = {};
    }

    public store(url: string, data?: any): any {
        // Store to chace synchronously
        this.cache[url] = {
            timestamp: new Date(),
            data: data
        }

        return data;
    }

    public async request(method: HttpMethod, url: string, options?: HttpOptions): Promise<any> {
        // Merge global options with request options
        options = {
            ...this.options,
            ...options
        };

        // Ajax API
        const id = Http.number++;

        // Make a call
        Log.d(`Http(#${id}): ${method.toUpperCase()} ${url}`);

        // Request done less than 1 second ago?
        if (options.cache != false && this.cache[url] && (Date.now() - this.cache[url].timestamp.getTime() <= CACHE_DELAY * 1000)) {
            Log.i(`Http(#${id}) (from-cache): ${method.toUpperCase()} ${url}`);
            return this.cache[url].data;
        }

        // Response result
        let response: Response = null;

        // Do request
        try {
            let headers = {
                "Accept": options.dataType,
                "Content-Type": options.contentType,
                "Authorization": options.authorization,
                "User-Uuid": options.uuid,
                ...options.headers
            };

            if (options.form) {
                delete headers["Content-Type"];
            }

            response = await fetch(url, {
                method: method,
                mode: options.mode || "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers,
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: options.form ?? JSON.stringify(options.data)
            });
        }
        catch (e) {
            Log.d(`Http(#${id}): Exception`);

            // Response text
            let text = await response?.text();

            // OnError handler
            this.onError({
                status: response?.status,
                response: text
            });

            throw {
                status: response?.status,
                response: text
            };
        }

        // OnResponse handler
        this.onResponse(response);

        // HTTP status 300 or 400?
        if (response.status >= 400) {
            Log.d(`Http(#${id}): Error`);

            // Response json
            let json = await response.json();

            // OnError handler
            this.onError({
                status: response.status,
                response: json
            });

            throw {
                status: response?.status,
                response: json
            };
        }

        // No content?
        if (response.status == 204 || response.headers.get("content-length") == "0") {
            return null;
        }
        // JSON response?
        else if (response.headers.get("content-type")?.lastIndexOf("application/json") > -1) {
            return this.store(url, await response.json());
        }
        // Unknown response type?
        else {
            Log.w(`Http(#${id}): Missing Content-Type header. Trying to parse as JSON first.`);

            // Unknown content type, will try to parse JSON first
            try {
                return this.store(url, await response.json());
            }
            catch (e) {
                return this.store(url, await response.text());
            }
        }
    }
}

export interface CacheEntry {
    timestamp: Date;
    data: any;
}

export interface HttpOptions {
    mode?: "cors" | "no-cors";
    authorization?: string;
    uuid?: string;
    contentType?: string;
    dataType?: string;
    data?: any;
    form?: FormData;
    timeout?: number;
    cache?: boolean;
    headers?: { [ name: string]: string }
}

export enum HttpMethod {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete"
}
