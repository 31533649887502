import "./human-detail.scss";
import * as template from "./human-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { HumanDetailOptions } from "./types";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { Browser } from "catalog/views/browser";
import { LunaHumanFaceBrowser } from "../luna-human-face-browser/luna-human-face-browser";
import { BrowserScreen } from "catalog/components/browser-screen/browser-screen";
import { LunaHumanImageInput } from "../luna-human-image-input/luna-human-image-input";
import { LunaHumanBodyBrowser } from "../luna-human-body-browser/luna-human-body-browser";
import { ContentView } from "invipo/views/content-view";

export class HumanDetail extends Detail<InvipoContext, HumanDetailOptions> {
    public isSearchBrowser: boolean;
    public isFaceBrowser: boolean;
    public isBodyBrowser: boolean;

    // Components
    public tabs: Tabs;

    constructor(context: InvipoContext, options: HumanDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        this.isSearchBrowser = !!document.querySelector("[component=HumanSearchBrowser]");
        this.isFaceBrowser = !!document.querySelector("[component=LunaHumanFaceBrowser]");
        this.isBodyBrowser = !!document.querySelector("[component=LunaHumanBodyBrowser]");
    }

    public openImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.HumanDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async searchFace(): Promise<void> {
        // Find Luna Face Browser component
        let browser: LunaHumanFaceBrowser = (document.querySelector("[component=LunaHumanFaceBrowser]") as any)?.component;

        if (!browser) {
            let view: ContentView = <ContentView>this.context.application.currentView;
            view.setContent("LunaHumanFaceBrowser");

            browser = (document.querySelector("[component=LunaHumanFaceBrowser]") as any)?.component;
        }

        this.searchHuman(browser);
    }

    public async searchBody(): Promise<void> {
        // Find Luna Face Browser component
        let browser: LunaHumanBodyBrowser = (document.querySelector("[component=LunaHumanBodyBrowser]") as any)?.component;

        if (!browser) {
            let view: ContentView = <ContentView>this.context.application.currentView;
            view.setContent("LunaHumanBodyBrowser");

            browser = (document.querySelector("[component=LunaHumanBodyBrowser]") as any)?.component;
        }

        this.searchHuman(browser);
    }

    public async searchHuman(browser: LunaHumanFaceBrowser | LunaHumanBodyBrowser): Promise<void> {
        // Put photo into the filter
        const input = <LunaHumanImageInput>browser.table.form.options.fieldsets[0].fields[0];
        if (!input) return;

        let base64 = this.options.human.images[0].content;
        if (!base64) {
            base64 = await this.getBase64Image(`${this.context.options.host}/download${this.options.human.images[0].url}`);
        }

        await input.setImage(base64);

        // Reload the data
        browser.table.load()
    }

    public async getBase64Image(url: string) {
        const response = await fetch(url)
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        });
    }

}
