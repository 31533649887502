import "./vehicle-watchlist-settings.scss";
import * as template from "./vehicle-watchlist-settings.hbs";

import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { VehicleWatchlistSettingsOptions } from "./types";
import { TextInput } from "muklit/components/text-input/text-input";
import { Form } from "muklit/components/form/form";

export class VehicleWatchlistSettings extends MuklitComponent<InvipoContext, VehicleWatchlistSettingsOptions> {

    // Components
    public nightTimeForm: Form;

    // Properties
    private config: any;

    constructor(context: InvipoContext, options?: VehicleWatchlistSettingsOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createNighttimeForm();

        // Register components that will be automatically attached
        this.registerComponent(this.nightTimeForm, "nighttime-form");
    }

    public async load(): Promise<void> {
        // Load configuration for night time
        this.config = await this.context.invipo.getConfig("vehicles");

        // Set data to form
        this.nightTimeForm.setData(this.config.vehicles?.strategy?.Nocturnal);

        // Update view
        this.invalidate(true);
    }

    public createNighttimeForm () {
        const startHour = new TextInput(this.context, {
            style: "Light",
            name: "startHour",
            label: "forms.fields.startHour",
            format: "Number",
            width: 280,
            bright: false,
            value: this.config?.vehicles?.strategy?.Nocturnals.startHour,
            required: true
        });

        startHour.onKey = () => {
            // Show buttons
            this.query("div.nighttime div.buttons").classList.remove("hidden");
        }

        const endHour = new TextInput(this.context, {
            style: "Light",
            name: "endHour",
            label: "forms.fields.endHour",
            format: "Number",
            width: 280,
            bright: false,
            value: this.config?.vehicles?.strategy?.Nocturnals.endHour,
            required: true,
        });

        endHour.onKey = () => {
            // Show buttons
            this.query("div.nighttime div.buttons").classList.remove("hidden");
        }

        this.nightTimeForm = new Form(this.context, {
            style: "Light",
            fieldsets: [{
                name: "nighttime",
                fields: [
                    startHour,
                    endHour
                ]
            }]
        });
        this.nightTimeForm.onSubmit = () => this.saveNighttime();
    }

    public async saveNighttime () {
        // Validate form
        if (!this.nightTimeForm.validate()) return;

        // Get data from form
        if (!this.config.vehicles) this.config.vehicles = {};
        if (!this.config.vehicles.strategy) this.config.vehicles.strategy = {};
        this.config.vehicles.strategy = { "Nocturnal": this.nightTimeForm.getData() };

        // Update vehicles configuration data
        try {
            await this.context.invipo.postConfig("vehicles", this.config.vehicles)
        }
        catch (e) {
            if (e.status == 422) {
                this.nightTimeForm.setValidationErrors(e.response);
                return;
            }
        }

        // Redraw
        this.invalidate();
    }
}
