// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.partial-snapshot {
  display: block;
  cursor: pointer !important;
  user-select: none;
  border-radius: 4px;
  margin: 8px 4px;
}`, "",{"version":3,"sources":["webpack://./src/invipo/partials/snapshot/snapshot.scss"],"names":[],"mappings":"AAGA;EACE,cAAA;EACA,0BAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AAFF","sourcesContent":["@import \"muklit/styles/mixins\";\r\n@import \"muklit/styles/colors\";\r\n\r\nimg.partial-snapshot {\r\n  display: block;\r\n  cursor: pointer !important;\r\n  user-select: none;\r\n  border-radius: 4px;\r\n  margin: 8px 4px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
