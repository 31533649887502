// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[component=LunaHumanFaceBrowser] div.muklit-select,
div[component=LunaHumanFaceBrowser] div.muklit-autocomplete {
  position: relative;
}
div[component=LunaHumanFaceBrowser] div.muklit-select div.muklit-overflow-menu,
div[component=LunaHumanFaceBrowser] div.muklit-autocomplete div.muklit-overflow-menu {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/safety/luna-human-face-browser/luna-human-face-browser.scss"],"names":[],"mappings":"AACI;;EAEI,kBAAA;AAAR;AAEQ;;EACI,kBAAA;AACZ","sourcesContent":["div[component=LunaHumanFaceBrowser] {\r\n    div.muklit-select,\r\n    div.muklit-autocomplete {\r\n        position: relative;\r\n\r\n        div.muklit-overflow-menu {\r\n            position: relative\r\n        }\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
