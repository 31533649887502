import { ItemSelect } from "../../common/item-select/item-select";
import { HumanBrowser } from "../human-browser/human-browser";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { HumanSearchBrowserOptions } from "./types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { FilterTab } from "../../../../muklit/components/filter/types";
import { ContentView } from "../../../views/content-view";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { LunaHumanImageInput } from "../luna-human-image-input/luna-human-image-input";
import { Autocomplete } from "muklit/components/autocomplete/autocomplete";

export class HumanSearchBrowser extends HumanBrowser<HumanSearchBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/humans-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.HumanBrowser.title",
                tabs: [
                    {
                        name: "HumanSearchBrowser",
                        label: "components.HumanSearchBrowser.title",
                        selected: true
                    },
                    {
                        name: "LunaHumanFaceBrowser",
                        label: "components.LunaHumanFaceBrowser.title"
                    },
                    {
                        name: "LunaHumanBodyBrowser",
                        label: "components.LunaHumanBodyBrowser.title",
                    },
                    {
                        name: "WatchlistHumanManager",
                        label: "components.WatchlistHumanManager.title",
                    }
                ],
                items: [
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                toggled: true,
                togglable: false
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "HumansData",
                                items: [],
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "gender",
                                label: "forms.fields.gender",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanGender")),
                                multiselect: true
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "emotion",
                                label: "forms.fields.emotion",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanEmotion")),
                                multiselect: true
                            }),
                            new Autocomplete(this.context, {
                                style: "Light",
                                name: "mask",
                                label: "forms.fields.mask",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanMask")),
                            }),
                        ]
                    },
                    {
                        name: "age",
                        type: "Inline",
                        fields: [
                            new TextInput(this.context, {
                                style: "Light",
                                name: "age.min",
                                label: "forms.fields.ageMin",
                                placeholderText: "forms.placeholders.years"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "age.max",
                                label: "forms.fields.ageMax",
                                placeholderText: "forms.placeholders.years"
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "gender",
                        type: "String",
                        property: (data: any) => {
                            return data.gender ? this.context.locale.getMessage(`enums.HumanGender.${data.gender}`) : "";
                        },
                        label: "tables.columns.gender",
                        width: 100,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "age",
                        type: "Number",
                        property: "age",
                        label: "tables.columns.age",
                        align: "Right",
                        width: 60,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Change component
        this.table.onTabSelect = (item: FilterTab) => {
            // Get current view as content view
            let view: ContentView = <ContentView>this.context.application.currentView;

            // Change view
            view.setContent(item.name);
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

}
