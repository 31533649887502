import "./traffic-counter-overview.scss";
import * as template from "./traffic-counter-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { TrafficCounterOverviewOptions } from "./types";
import { Helpers } from "hiyo/helpers";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { TrafficVolumeSegmentLineLayer } from "../../../layers/traffic/traffic-volume-segment-line-layer";
import { TrafficVolumeSegmentSymbolLayer } from "../../../layers/traffic/traffic-volume-segment-symbol-layer";
import { GoogleOrthophotoLayer } from "../../../layers/custom/google-orthophoto-layer";
import { TrafficVolumeSegmentLabelLayer } from "../../../layers/traffic/traffic-volume-segment-label-layer";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelKpis } from "../../common/panel/types";
import { Point } from "geojson";
import { InvipoHelpers } from "../../../invipo-helpers";

export class TrafficCounterOverview extends Panel<TrafficCounterOverviewOptions> {

    // Properties
    public volume: PanelChart;
    public kpis: PanelKpis;

    constructor(context: InvipoContext, options?: TrafficCounterOverviewOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        if (this.item && this.map) {
            this.map.setCenter((<Point>this.item.geometry.location).coordinates);
        }
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "GoogleOrthophoto",
            center: this.context.options.home.center,
            zoom: 18,
            minZoom: 5,
            maxZoom: 20
        });

        // Add Google custom layer
        this.map.onMapLoad = () => {
            this.map.mapboxMap.addLayer(new GoogleOrthophotoLayer(this.context).options.layer);
        }

        // Register map layers
        this.map.addLayer(new TrafficVolumeSegmentLineLayer(this.context, this.options.itemId));
        this.map.addLayer(new TrafficVolumeSegmentSymbolLayer(this.context, this.options.itemId));
        this.map.addLayer(new TrafficVolumeSegmentLabelLayer(this.context, this.options.itemId));
        //this.map.addLayer(new ItemCircleLayer(this.context, [this.item], "Device"));

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public async extraLoad(): Promise<void> {
        // Laod data
        let last = await this.context.invipo.getDataset("traffic-data", "pageSize=1&sort=interval.from:desc");

        // Build hour chart
        this.volume = {
            type: "Bar",
            size: "Medium",
            length: 24,
            label: "components.TrafficCounterOverview.todayVolume",
            series: [],
            range: [],
            legend: []
        }

        // Interval
        let from = new Date(new Date().setHours(0, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));

        // Load data
        let today = await this.context.invipo.getQuery("traffic-by-hour", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);
        let yesterday = await this.context.invipo.getQuery("traffic-by-hour", `item.id=${this.options.itemId}&from=${new Date(from.getTime() - 86400000).toISOString()}&to=${new Date(to.getTime() - 86400000).toISOString()}`);

        // Get max count to adjust chart optimal height
        let max = Math.max(...today.map(x => x.count)) * 1.1;

        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = today.find(x => x.timestamp == from.toISOString());

            // Data for hour exists?
            if (d) {
                // Total counts
                this.volume.series.push(
                    [
                        {
                            timestamp: d.timestamp,
                            valueY: Helpers.toNumber(d.count),
                            valueX: h.toString().padStart(2, "0"),
                            percent: Helpers.range(0, 100, 0, max, d.count),
                            color: InvipoHelpers.toChartColor(1)
                        }
                    ]
                );
            }
            else {
                this.volume.series.push(
                    [
                        {
                            timestamp: from.toISOString()
                        }
                    ]
                );
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }

        // Build volume KPIs
        this.kpis = {
            label: "components.TrafficCounterOverview.volumeStats",
            size: "Third",
            data: [
                {
                    label: "components.TrafficCounterOverview.lastData",
                    value: (last?.data?.length) ? Helpers.toShortDateTimeString(last.data[0].interval.from) : this.context.locale.getMessage("common.unknown"),
                    type: Helpers.toAge(last.data[0]?.interval.from) > (this.context.config.aggregations.traffic.interval * 60) ? "Negative" : "Positive",
                    description: Helpers.toDuration(Helpers.toAge(last.data[0]?.interval.from))
                },
                {
                    label: "components.TrafficCounterOverview.todayVolume",
                    value: `${Helpers.toNumber(today.map(x => x.count).reduce((x, y) => { return x + y}, 0))} ${this.context.locale.getMessage("units.vehicles")}`,
                    type: today.map(x => x.count).reduce((x, y) => { return x + y}, 0) == 0 ? "Negative" : "Positive",
                    description: Helpers.toDateString(from.getTime())
                },
                {
                    label: "components.TrafficCounterOverview.yesterdayVolume",
                    value: `${Helpers.toNumber(yesterday.map(x => x.count).reduce((x, y) => { return x + y}, 0))}`,
                    type: yesterday.map(x => x.count).reduce((x, y) => { return x + y}, 0) == 0 ? "Negative" : "Positive",
                    description: Helpers.toDateString(from.getTime() - 86000000)
                },
            ]
        }
    }
}
