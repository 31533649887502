import "./autocomplete.scss";
import * as template from "./autocomplete.hbs";
import { Context } from "hiyo/context";
import { AutocompleteOptions } from "./types";
import { OverflowMenu } from "../overflow-menu/overflow-menu";
import { MenuItem } from "../overflow-menu/types";
import { Input } from "../input/input";
import { InputValue } from "../input/types";

const CLASS_ACTIVE = "muklit-autocomplete-active";

export class Autocomplete<T extends Context = Context, U extends AutocompleteOptions = AutocompleteOptions> extends Input<T, U> {

    // Components
    public menu: OverflowMenu;

    constructor(context: T, options: U) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();

        // String value rearrangement
        if (this.options.value && typeof this.options.value == "string") {
            let item = this.options.items.find(x => x.name == this.options.value);

            // Item found?
            if (item) {
                this.options.value = {};
                this.options.value[item.name] = item.label;
            }
        }

        // Disable select if no items
        this.options.disabled = this.options.items.length == 0;

    }

    private index = -1;
    public onKeyDown(e: KeyboardEvent): void {
        switch (e.key) {
            case "ArrowUp":
            case "ArrowDown":
                e.preventDefault();
                e.stopPropagation();
                break;
        }
    }

    public onKeyUp(e: KeyboardEvent): void {
        const input: HTMLInputElement = this.query("input");

        switch (e.key) {
            case "ArrowUp":
                e.preventDefault();
                e.stopPropagation();

                this.index = Math.max(this.index - 1, 0);
                this.menu.hilight(this.index);

                break;
            case "ArrowDown":
                e.preventDefault();
                e.stopPropagation();

                this.index = Math.min(this.index + 1, this.menu.options.items.length - 1);
                this.menu.hilight(this.index);

                break;
            case "Enter":
                if (this.index >= 0) {
                    // Confirm selection
                    let index = -1;
                    const itemIndex = this.menu.options.items.findIndex((x, i) => {
                        return x.hidden == false && ++index == this.index;
                    })

                    this.menu.select(itemIndex);
                }
                break;
            case "Escape":
                e.preventDefault();
                e.stopPropagation();

                // Clear input
                this.setValue(null);

                // Remove selection
                this.index = -1;
                this.menu.hilight();
                this.menu.filter();
                this.menu.hide();

                // Submit empty selection
                this.onSubmit();
                break;
            default:
                // Filter menu items
                this.menu.filter(input.value);

                // Reset selected menu items
                this.index = -1;
                this.menu.hilight(-1);
                break;
        }
    }

    private createMenu(): void {
        // Create component
        this.menu = new OverflowMenu(this.context, {
            style: this.options.style,
            items: this.options.items,
            start: "BottomLeft",
            anchor: "TopLeft",
            offset: [0, 4]
        })

        // Reset value when selected from menu
        this.menu.onSelect = (item: MenuItem) => {
            let value: InputValue;

            value = {};

            // Add new value
            value[item.name] = item.label;

            // Set new value
            this.setValue(value);

            // OnSubmit handle
            this.onSubmit();
        }

        // Add active state
        this.menu.onAttach = () => {
            this.element?.classList.add(CLASS_ACTIVE);
        }

        // Remove active state
        this.menu.onDetach = () => {
            this.element?.classList.remove(CLASS_ACTIVE);
        }
    }

    public select(): void {
        // Empty items?
        if (this.options.items.length == 0) {
            return;
        }

        // Make right position
        this.menu.options.width = this.element.offsetWidth;

        // Reassign items
        this.menu.options.items = this.options.items;

        // Show menu
        this.menu.show(null, undefined, undefined, this.element);
    }

    public setValue(value: string | InputValue) {
        // Sets value to options
        this.options.value = value;

        // Set value options
        if (value == null) {
            this.options.value = null;
        }
        else if (typeof value == "object") {
            this.options.value = value;
        }
        else {
            // If value is plain string, we try to find first item fitting the name
            let item = this.options.items.find(x => x.name == value);

            // Item found?
            if (item) {
                this.options.value = {};
                this.options.value[value] = item.label;
            }
            // Otherwise we will assign plain value
            else {
                this.options.value = value;
            }
        }

        // Reset invalid state and messageText once we select new value
        this.options.invalid = null;
        this.options.messageText = null;

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }

        // OnClear handler
        if (value == null) {
            this.onClear();
        }
    }

    public async load(): Promise<void> {
        // Disable if select has no items
        this.setDisabled(!this.options.items?.length);
    }
}
